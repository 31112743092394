import { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from 'react-helmet-async';
import { Link, Link as RouterLink, useNavigate, useParams, useLocation, NavLink } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Multiselect from 'multiselect-react-dropdown';
import "react-data-table-component-extensions/dist/index.css";
import * as React from 'react';
import $ from 'jquery';
import TableToExcel from '@linways/table-to-excel';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Button, Typography, Container, Box, Modal } from '@mui/material';
import * as userActions from "../../actions/userActions";
import config from "../../config";
import { encryptData } from '../../utils/cryptoUtils';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import '../common.scss';
import * as reportActions from "../../actions/reportActions";
import Loader from "../../components/Loader";

import AccessDenied from '../common/AccessDenied';
import * as beatPlanActions from "../../actions/beatPlanActions";
import * as campaignsActions from "../../actions/campaignsActions";
import * as masterAction from "../../actions/masterAction";

// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------
const BeatPlanPage = (props) => {
    const theme = useTheme();
    const { id } = useParams();
    const location = useLocation();
    const { actions, usersData } = props;
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const navigate = useNavigate();

    const [permissions, setPermissions] = useState([]);
    const [userRoleType, setUserRoleType] = useState('User');
    const [loggeInUserID, setLoggeInUserID] = useState('');

    const [allLoggedinUserChild, setAllLoggedinUserChild] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [allBeatPlans, setAllBeatPlans] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');

    const [sendCrifOpenModal, setSendCrifOpenModal] = React.useState(false);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [sendCrifFormData, setSendCrifFormData] = useState({ email_address: '' });
    const [preloaderStatus, setPreloaderStatus] = useState(false);

    const [viewModulePermissionStatus, setViewModulePermissionStatus] = useState(false);
    const [userHasPermission, setUserHasPermission] = useState(true);

    const [beatPlanLocation, setBeatPlanLocation] = useState([]);
    const [allCampaigns, setAllCampaigns] = useState([]);
    const [selectedVisitType, setSelectedVisitType] = useState([]);

    const [allModeOfTransport, setAllModeOfTransport] = useState([]);
    const [selectedModeOfTransport, setSelectedModeOfTransport] = useState('');
    const [selectedItrFiles, setSelectedItrFiles] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [selectedModeOfTransportName, setSelectedModeOfTransportName] = useState('');
    const [otherInputShow, setOtherInputShow] = React.useState(false);
    const [fileInputShow, setFileInputShow] = React.useState(false);

    useEffect(() => {
        // check user is logged-in or not
        const authToken = localStorage.getItem('authToken');
        if (authToken === null) {
            navigate('/login');
        }
    }, []);

    useEffect(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.getUserModuleData(requestData);
        response.then((result) => {
            // console.log("Promise resolved -  lead list:", result);
            if (result.data.success === 1) {
                const permissionsData = result.data.data;
                setPermissions(permissionsData);
                const hasViewPermissions = permissionsData.find(permission => permission.module_name === 'Beat Plan')?.view_permission;
                setPermissions(permissionsData);
                setViewModulePermissionStatus(hasViewPermissions);
                setUserHasPermission(hasViewPermissions);

                if (Object.prototype.hasOwnProperty.call(result.data, 'user_info') && Object.prototype.hasOwnProperty.call(result.data.user_info[0], 'role_type') && result.data.user_info[0].role_type !== '') {
                    setUserRoleType(result.data.user_info[0].role_type);
                    // alert(result.data.user_info[0].role_type);
                    setLoggeInUserID(result.data.user_info[0].user_id);
                }
            }
        }).catch((error) => {
            // console.error("Promise rejected -  lead list:", error);
        });
    }, [setPermissions, setUserHasPermission, setUserRoleType, setLoggeInUserID]);

    const checkLogout = () => {
        const authToken = localStorage.getItem('authToken');
        const logInTimestamp = localStorage.getItem('logInTimestamp');

        if (logInTimestamp !== null) {
            const loginTimestamp = parseInt(logInTimestamp, 10);
            const currentTime = Math.floor(Date.now() / 1000);
            const timeElapsed = currentTime - loginTimestamp;
            const autoLogoutTimeInSecod = config.AUTO_LOGOUT_TIME_IN_SECONDS;

            if (timeElapsed >= autoLogoutTimeInSecod) {
                const params = { 'login_token': authToken };
                console.log("params : ", params);
                const requestData = encryptData(params);
                const response = actions.userLogout(requestData);
                response.then((result) => {
                    if (result.data.success === 1) {
                        const data = {};
                        actions.loadUserAuth(false);
                        actions.loginDataSuccess(data);
                        localStorage.setItem("authToken", '');
                        localStorage.setItem("logInTimestamp", '');
                        navigate("/login");
                    }
                }).catch((error) => {
                    console.log(error);
                });
            }
        }
    }

    useEffect(() => {
        checkLogout();
    });

    useEffect(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.getLoggedinUserChild(requestData, false);

        response.then((result) => {
            // console.log("Promise resolved -  lead Status data:", result); 
            if (result.data.success === 1) {
                const results = result.data.data.data;

                const extractedData = results.map(item => {
                    return { id: item.user_id, name: item.name };
                });
                const resultArr = Object.values(extractedData);
                setAllLoggedinUserChild(resultArr);
            }
        }).catch((error) => {
            //  console.error("Promise rejected -  source data :", error); 
        });
    }, [setAllLoggedinUserChild]);

    useEffect(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.campaignData(requestData, false);
        response.then((result) => {
        //   console.log("Promise resolved -  campaignData:", result);
          if (result.data.success === 1) {
            const results = result.data.data.data;
            const resultArr = Object.keys(results).map((key) => results[key]);
            const filteredResultArr = resultArr.filter((item) => item.status === "1");
            const sortedResultArr = filteredResultArr.sort((a, b) =>
                a.campaign_name.localeCompare(b.campaign_name)
            );
            const extractedData = sortedResultArr.map(item => {
              return { id: item.id, name: item.campaign_name };
            });
            const resultArrObj = Object.values(extractedData);
            setAllCampaigns(resultArrObj);
          }
        }).catch((error) => {
          //  console.error("Promise rejected -  campaignData :", error); 
        });
    
    }, [setAllCampaigns]);

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    useEffect(() => {
        setPreloaderStatus(true);

        const today = new Date();
        const todayFormatted = formatDate(today);

        setStartDate(todayFormatted);
        setEndDate(todayFormatted);

        setValue("start_date", todayFormatted);
        setValue("end_date", todayFormatted);

        const params = { 'start': '0', 'length': config.MAX_RECORDS, 'start_date': todayFormatted, 'end_date': todayFormatted };
        const requestData = encryptData(params);
        const response = actions.getBeatPlanData(requestData, false);
        response.then((result) => {
            // console.log("Promise resolved -  getBeatPlanData:", result);
            if (result.data.success === 1) {
                setPreloaderStatus(false);
                const results = result.data.data.beat_plan_data;
                const resultArr = Object.keys(results).map((key) => results[key]);
                setAllBeatPlans(resultArr);
            } else {
                setPreloaderStatus(false);
                setAllBeatPlans([]);
            }
        }).catch((error) => {
            setPreloaderStatus(false);
            console.error("Promise rejected -  getBeatPlanData:", error);
        });
    }, [setAllBeatPlans]);

    const onSubmit = (data) => {
        setPreloaderStatus(true);
        const params = {
            'start': '0',
            'length': config.MAX_RECORDS,
            'assign_to': selectedUsers,
            'start_date': data.start_date,
            'end_date': data.end_date,
            "visit_type":selectedVisitType
        };

        setStartDate(data.start_date);
        setEndDate(data.end_date);

        const requestData = encryptData(params);
        const response = actions.getBeatPlanData(requestData, false);
        response.then((result) => {
            // console.log("Promise resolved -  lead list:", result);
            if (result.data.success === 1) {
                setPreloaderStatus(false);
                const results = result.data.data.beat_plan_data;
                const resultArr = Object.keys(results).map((key) => results[key]);
                setAllBeatPlans(resultArr);
            } else {
                setPreloaderStatus(false);
                setAllBeatPlans([]);
            }
        }).catch((error) => {
            setPreloaderStatus(false);
            console.error("Promise rejected -  lead list:", error);
        });
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1); // Reset to the first page when searching
    };

    const filteredItems = allBeatPlans.filter(item => {
        const companyName = item.companyName?.toLowerCase() || '';
        const assignedUser = item.assignedUser?.toLowerCase() || '';
        const createdByName = item.createdByName?.toLowerCase() || '';
        return (
            companyName.includes(searchQuery.toLowerCase()) ||
            assignedUser.includes(searchQuery.toLowerCase()) ||
            createdByName.includes(searchQuery.toLowerCase())
        );
    });

    const startIndex = (currentPage - 1) * config.HUNDREAD_PER_PAGE;
    const endIndex = startIndex + config.HUNDREAD_PER_PAGE;
    const paginatedItems = filteredItems.slice(startIndex, endIndex);
    const totalPages = Math.ceil(filteredItems.length / config.HUNDREAD_PER_PAGE);

    const exportBeatPlan = async () => {
        const table = document.getElementById('exportBeatPlanExcelFile');
        
        const headersRow = table.querySelector('thead tr');
        const headers = Array.from(headersRow.querySelectorAll('th'));
        const actionColumnIndex = headers.findIndex(th => th.innerText.trim() === 'Action');

        headersRow.removeChild(headers[actionColumnIndex]);
    
        TableToExcel.convert(table, {
            name: 'Beat Plan.xlsx',
            sheet: {
                name: 'Beat Plan',
            },
        });

        headersRow.appendChild(headers[actionColumnIndex]);
    };

    useEffect(() => {

        setAllModeOfTransport([]);
        
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.getModeOfTransport(requestData, false);

        response.then((result) => {
            // console.log("Promise resolved -  lead Status data:", result); 
            if (result.data.success === 1) {
                const results = result.data.data.data;

                const extractedData = results.map(item => {
                    return { id: item.id, name: item.mode_of_transport };
                });
                const resultArr = Object.values(extractedData);
                setAllModeOfTransport(resultArr);
            }
        }).catch((error) => {
            //  console.error("Promise rejected -  source data :", error); 
        });
    }, [setAllModeOfTransport]);

    const getLocation = (beatPlanInfo, beatPlanId, btnAction, id) => {

        setOpen(true);
        setSelectedItrFiles([]);
        setBeatPlanLocation([]);
        setOtherInputShow(false);
        setFileInputShow(false);

        const modeOfTransport = beatPlanInfo?.beat_plan_start_end_data?.mode_of_transport;

        if (modeOfTransport) {
            setSelectedModeOfTransport(modeOfTransport);
        } else {
            setSelectedModeOfTransport('');
            setSelectedModeOfTransportName('');
            setOtherInputShow(false);
        }

        const modeOfTransportName = beatPlanInfo?.beat_plan_start_end_data?.modeOfTransportName;

        if(modeOfTransportName === 'Other' || modeOfTransportName === 'Car'){
            console.log("modeOfTransportName : ", modeOfTransportName);
            setFileInputShow(true);
        } else{
            setFileInputShow(false); 
        }

        const transportSpecification = beatPlanInfo?.beat_plan_start_end_data?.specification;

        if(transportSpecification){
            setValue("specification", transportSpecification);
            setOtherInputShow(true);
        } else {
            setOtherInputShow(false);
        }

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {

                const latitude = position.coords.latitude;
                const longitude = position.coords.longitude;

                setBeatPlanLocation({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                    beatPlanId,
                    btnAction,
                    id
                });
            }, (error) => {
                toast.error('Error getting location:', error, {
                    position: "top-right",
                });
            });
        } else {
            toast.error("Google location is not supported by this browser.", {
                position: "top-right",
            });
        }
    }

    const handleClose = () => {
        setOpen(false);
        setSelectedItrFiles([]);
        setBeatPlanLocation([]);
        setSelectedModeOfTransport('');
        setSelectedModeOfTransportName('');
    };

    const handleItrFileChange = (e) => {
        const files = Array.from(e.target.files);
        const base64Files = [];

        files.forEach((file) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                const base64Data = event.target.result;
                const fileName = file.name;
                base64Files.push({ file: base64Data, name: fileName });
                if (base64Files.length === files.length) {
                    setSelectedItrFiles(base64Files);
                }
            };
            reader.readAsDataURL(file);
        });
    };

    const handleFormSubmit = (data) => {

        if (!selectedModeOfTransport || selectedModeOfTransport === '') {
            toast.error("Please select mode of transport.", {
                position: "top-right",
            });
            return;
        }
        
        if (selectedModeOfTransportName === 'Other' && data.specification === '') {
            toast.error("Specification is blank.", {
                position: "top-right",
            });
            return;
        }
        
        if (fileInputShow && selectedItrFiles.length === 0) {
            toast.error("Please upload Vehicle Registration Number/Image.", {
                position: "top-right",
            });
            return;
        }
        
        if (beatPlanLocation.latitude === "" && beatPlanLocation.longitude === "") {
            toast.error("Google location is not supported.", {
                position: "top-right",
            });
            return;
        }        

        setPreloaderStatus(true);

         if(beatPlanLocation.beatPlanId === undefined){
            setPreloaderStatus(false);
            toast.error("Please enable location.", {
                position: "top-right",
            });
         }else{

        const params = {
            'id': beatPlanLocation.id,
            'beat_plan_id': beatPlanLocation.beatPlanId,
            'btnAction': beatPlanLocation.btnAction,
            'latitude': beatPlanLocation.latitude,
            'longitude': beatPlanLocation.longitude,
            'mode_of_transport': selectedModeOfTransport,
            'upload_image' : (!fileInputShow ? '' : selectedItrFiles),
            'specification': (!otherInputShow ? '' : data.specification),
        };

        // console.log("params : ", params);

        const requestData = encryptData(params);
        const response = actions.setBeatPlanStatus(requestData, false);
        response.then((result) => {
            if (result.data.success === 1) {

                toast.success(result.data.message, {
                    position: "top-right",
                });

                setOpen(false);
                setSelectedItrFiles([]);
                setBeatPlanLocation([]);
                setSelectedModeOfTransport('');
                setSelectedModeOfTransportName('');

                const params = { 'start': '0', 'length': config.MAX_RECORDS, 'start_date': startDate, 'end_date': endDate };
                const requestData = encryptData(params);
                const response = actions.getBeatPlanData(requestData, false);
                response.then((result) => {
                    // console.log("Promise resolved -  getBeatPlanData:", result);
                    if (result.data.success === 1) {
                        setPreloaderStatus(false);
                        const results = result.data.data.beat_plan_data;
                        const resultArr = Object.keys(results).map((key) => results[key]);
                        setAllBeatPlans(resultArr);
                    } else {
                        setPreloaderStatus(false);
                        setAllBeatPlans([]);
                    }
                }).catch((error) => {
                    setPreloaderStatus(false);
                    console.error("Promise rejected -  getBeatPlanData:", error);
                });

                setPreloaderStatus(false);
            } else {
                setPreloaderStatus(false);
                toast.error(result.data.message, {
                    position: "top-right",
                });
            }
        }).catch((error) => {
            setPreloaderStatus(false);
            console.error("Promise rejected -  setBeatPlanStatus: ", error);
        });
    }
    };

    const handleModeOfTransport = (e) => {

        const selectedValue = e.target.value;
        const selectedName = e.target.options[e.target.selectedIndex].getAttribute('data-name');

        setSelectedModeOfTransport(selectedValue);
        setSelectedModeOfTransportName(selectedName);

        if(selectedName === 'Other'){
            setOtherInputShow(true);
        } else {
            setOtherInputShow(false);
        }

        if(selectedName === 'Other' || selectedName === 'Car'){
            setFileInputShow(true);
        } else {
            setFileInputShow(false);
        }
    };

    if (!userHasPermission) {
        // Redirect or render an access denied message
        return <AccessDenied />;
    }

    return (
        <>
            <Helmet>
                <title>Beat Plans | Ckers</title>
            </Helmet>

            <Container>
                <div className="d-flex align-items-center justify-content-between mb-0">
                    <span>&nbsp;</span>
                    <RouterLink to="/dashboard/create-beat-plan" className="add-btn btn-primary btn">
                        <i className="bi bi-plus">&nbsp;</i> Create Beat Plan
                    </RouterLink>
                </div>
                
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div id='main-wrap' className='mb-4 filter'>
                        <div className='row mx-0'>
                            <div className='col'>
                                <div className='form-group'>
                                    <span>User Name</span>
                                    <div className="text-dark">
                                        <Multiselect
                                            id="created_by"
                                            options={allLoggedinUserChild.map((option) => ({
                                                key: option.id,
                                                value: option.name,
                                            }))}
                                            displayValue="value"
                                            onSelect={(selectedItems) => {
                                                const selectedValues = selectedItems.map(item => item.key);
                                                setSelectedUsers(selectedValues);
                                            }}
                                            onRemove={(selectedItems) => {
                                                const selectedValues = selectedItems.map(item => item.key);
                                                setSelectedUsers(selectedValues);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className='col'>
                                <div className='form-group'>
                                    <span>Visit Type</span>
                                    <div className="text-dark">
                                        <Multiselect
                                            id="visit_type"
                                            options={allCampaigns.map((option) => ({
                                                key: option.id,
                                                value: option.name,
                                            }))}
                                            displayValue="value"
                                            onSelect={(selectedItems) => {
                                                const selectedValues = selectedItems.map(item => item.key);
                                                setSelectedVisitType(selectedValues);
                                            }}
                                            onRemove={(selectedItems) => {
                                                const selectedValues = selectedItems.map(item => item.key);
                                                setSelectedVisitType(selectedValues);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='col'>
                                <div className='form-group'>
                                    <label htmlFor="start-date">
                                        <span>Select Start Date </span>
                                        <input type='date' name='start_date' className='form-control'{...register('start_date')} />
                                    </label>
                                    {errors.start_date && <span className="required">This field is required.</span>}
                                </div>
                            </div>
                            <div className='col'>
                                <div className='form-group'>
                                    <label htmlFor="end-date">
                                        <span>Select End Date</span>
                                        <input type='date' name='end_date' className='form-control'{...register('end_date')} />
                                    </label>
                                    {errors.end_date && <span className="required">This field is required.</span>}
                                </div>
                            </div>
                            <div className='col'>
                                <div className='form-group mb-2'>
                                    <span className='d-block'>&nbsp;</span>
                                    <button className='btn btn-primary'>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

                <div id="main-wrap">
                    <div className="row justify-content-between">
                        <div className="col-md-7">
                            <Typography className="line-initial" variant="h4" sx={{ mb: 0 }}>
                                Beat Plans
                            </Typography>
                        </div>
                        <div className='col-md-5 text-end ps-lg-5'>
                            <div className="form-group d-flex justify-content-end align-items-center">
                                <label htmlFor="search" className='w-100'>
                                    {/* Search : */}
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search Company name, Assigned to, Created by.. "
                                        id="search"
                                        value={searchQuery}
                                        onChange={handleSearchChange}
                                    />
                                </label>
                                <button className="btn btn-info text-white ms-3" onClick={() => exportBeatPlan()}>
                                    Export
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="table-wrap table-responsive">
                        <table className="table table-hover" id="exportBeatPlanExcelFile">
                            <thead>
                                <tr>
                                
                                    <th className="text-nowrap">User Name</th>
                                    <th className="text-nowrap">Scheduled Time</th>
                                    <th className="text-nowrap">Company Name</th>
                                    <th className="text-nowrap">Contact Name</th>
                                    <th className="text-nowrap">Phone Number</th>
                                    <th className="text-nowrap">Address</th>
                                    <th className="text-nowrap">Remark</th>
                                    <th className="text-nowrap">Visit Type</th>
                                    <th className="text-nowrap">GPS Location</th>
                                    {/* <th className="text-nowrap">Visit Status</th> */}
                                    <th className="text-nowrap">Created On</th>
                                    <th className="text-nowrap">Created By</th>
                                    <th className="text-nowrap">Beat Plan Action</th>
                                    <th className="text-nowrap">Action</th>
                                </tr>
                            </thead>

                            <tbody>
                                {paginatedItems.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.assignedUser}</td>
                                        <td>{item.scheduledTime}</td>
                                        <td>{item.companyName}</td>
                                        <td>{item.contact_name}</td>
                                        <td>{item.mobile}</td>
                                        <td>
                                            <p className='beat-plan-address'>{item.address}</p>
                                        </td>
                                        <td>
                                            <p className='beat-plan-remark'>{item.remarks}</p>
                                        </td>
                                        <td>{item.visitTypeName}</td>
                                        <td>
                                            <p className='beat-plan-remark'>{item.gpsLocation}</p>
                                            <p className='beat-plan-remark'>{item.gpsAddress}</p>
                                        </td>
                                        {/* <td>
                                            <span
                                            className={(item.visit_status === '2') ? "badge text-bg-success text-white" : (item.visit_status === '1') ? "badge text-bg-danger text-white" : "badge text-bg-warning text-white"}
                                            >{item.visitStatusName}</span>
                                        </td> */}
                                        <td>{item.created_date}</td>
                                        <td>{item.createdByName}</td>
                                        <td>
                                            {
                                                item.startGpsAddress !== null &&
                                                    item.startGpsAddress !== '' &&
                                                    item.startGpsLocation !== null &&
                                                    item.startGpsLocation !== '' &&
                                                    item.endGpsAddress !== null &&
                                                    item.endGpsAddress !== '' &&
                                                    item.endGpsLocation !== null &&
                                                    item.endGpsLocation !== '' &&
                                                    item.start_end_id !== null &&
                                                    item.start_end_id !== ''
                                                    ? (
                                                        <span className='badge text-bg-info text-white'>Completed</span>
                                                    ) : (
                                                        item.startGpsAddress !== null &&
                                                        item.startGpsAddress !== '' &&
                                                        item.startGpsLocation !== null &&
                                                        item.startGpsLocation !== '' &&
                                                        item.start_end_id !== null &&
                                                        item.start_end_id !== ''
                                                    ) ? (
                                                        <div className="btn-group dropstart">
                                                            <button className="btn btn-primary" onClick={() => getLocation(item, item.id, 'end', item.start_end_id)}>End</button>
                                                        </div>
                                                    ) : (
                                                        <div className="btn-group dropstart">
                                                            <button className="btn btn-primary" onClick={() => getLocation(item, item.id, 'start', null)}>Start</button>
                                                        </div>
                                                    )
                                            }
                                        </td>

                                        <td>
                                            <div className= {item.startGpsAddress !== null &&
                                                    item.startGpsAddress !== '' &&
                                                    item.startGpsLocation !== null &&
                                                    item.startGpsLocation !== '' &&
                                                    item.endGpsAddress !== null &&
                                                    item.endGpsAddress !== '' &&
                                                    item.endGpsLocation !== null &&
                                                    item.endGpsLocation !== '' &&
                                                    item.start_end_id !== null &&
                                                    item.start_end_id !== ''
                                                    ? "btn-group dropstart d-none" : "btn-group dropstart" }  title="Edit">
                                                <NavLink to={`/dashboard/edit-beat-plan/${btoa(String(item.id))}`}><i className="bi bi-pen" style={{ color: "black" }} /></NavLink>
                                            </div>
                                        </td>

                                    </tr>
                                ))}

                                {paginatedItems.length < 1 && (
                                    <>
                                        <tr>
                                            <td colSpan={11} className='text-center py-3 fw-bold'>No record found</td>
                                        </tr>
                                    </>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <nav className='mb-1'>
                        <ul className="pagination pagination-md justify-content-end">
                            {Array.from({ length: totalPages }, (_, index) => index + 1).map(page => (
                                <li className="page-item">
                                    <button
                                        key={page}
                                        onClick={() => handlePageChange(page)}
                                        disabled={currentPage === page}
                                        className="page-link"
                                    >
                                        {page}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </nav>
                </div>

                {preloaderStatus && <Loader />}
            </Container>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='modal-custom rounded'>
                    <div className='modal-head'>
                        <h4 id="modal-title">Beat Plan Information</h4>
                        <Button onClick={handleClose} color="primary" className=' btn'>
                            <i className="bi bi-x">&nbsp;</i>
                        </Button>
                    </div>
                    
                    <div className='modal-body'>
                        <div>
                            <form onSubmit={handleSubmit(handleFormSubmit)} encType="multipart/form-data">
                                <div className="row mx-0">
                                    
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="mode_of_transport">
                                                <span>Mode Of Transport <a style={{ color: "red" }}>*</a></span>
                                                <select 
                                                    id="mode_of_transport" 
                                                    className="form-select" 
                                                    {...register('mode_of_transport')} 
                                                    value={selectedModeOfTransport} 
                                                    onChange={handleModeOfTransport}
                                                >
                                                    <option value=''>Select Mode Of Transport</option>
                                                    {allModeOfTransport.length > 0 && allModeOfTransport.map((option) => (
                                                        <option key={option.id} value={option.id} data-name={option.name}>
                                                            {option.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </label>
                                            {errors.mode_of_transport && <span className="required">This field is required.</span>}
                                        </div>
                                    </div>
                                    
                                    {otherInputShow && <>
                                        
                                        <div className='col-md-6'>
                                            <div className='form-group'>
                                                <label htmlFor="company-name">
                                                    <span>Please Specify <a style={{ color: "red" }}>*</a></span>
                                                    <input type='text' id='specification' name='specification' className='form-control' {...register('specification')}
                                                    />
                                                </label>
                                                {errors.specification && <span className="required">This field is required.</span>}
                                            </div>
                                        </div>
                                    
                                    </>}
                                    
                                    {fileInputShow && <>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label htmlFor="itr_file">
                                                    <span>Upload Vehicle Registration Number/Image <a style={{ color: "red" }}>*</a></span>
                                                    <input
                                                        type='file'
                                                        id='itr_file'
                                                        name='itr_file'
                                                        className='form-control'
                                                        {...register('itr_file')}
                                                        onChange={handleItrFileChange}
                                                    />
                                                </label>
                                            </div>
                                        </div>
                                    </>}

                                    <div className="col-md-12">
                                        <div className="form-group mb-0">
                                            <button className="btn btn-primary mt-2"
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
}

function mapStateToProps(state) {
    return {
        getBeatPlanData: state.getBeatPlanData,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign(reportActions.default, userActions.default, beatPlanActions.default, campaignsActions.default, masterAction.default), dispatch),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(BeatPlanPage);