import React from 'react';
import { Navigate,useRoutes,Switch,Route,Routes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import UserPage from './pages/user/UserPage';
import LoginPage from './pages/login/LoginPage';
import Page404 from './pages/Page404';
import DashboardAppPage from './pages/dashboard/DashboardAppPage';
import UserCreatePage from './pages/user/UserCreatePage';
import ReportingEnginePage from './pages/ReportingEnginePage';
import UserRolePage from './pages/user_role/UserRolePage';
import DepartmentPage from './pages/department/DepartmentPage';
import DepartmentCreatePage from './pages/department/DepartmentCreatePage';
import UserRoleCreatePage from './pages/user_role/UserRoleCreatePage';

import RolePermissionPage from './pages/role_permission/RolePermissionPage';
import RolePermissionCreatePage from './pages/role_permission/RolePermissionCreatePage';

import LeadCreatePage from './pages/lead/LeadCreatePage';
import LeadsPage from './pages/lead/LeadsPage';
import AddEditLeadPage from './pages/lead/AddEditLeadPage';
import LQCPage from './pages/lead/LQCPage';
import LeadChecklistForm from './pages/lead/LeadChecklistForm';
import LeadQualificationChecklistCreate from './pages/lead/LeadQualificationChecklistCreate';
import ChecklistsPage from './pages/lead/ChecklistsPage';
import LeadProfilePage from './pages/lead/LeadProfilePage';
import LeadProfileCreatePage from './pages/lead/LeadProfileCreatePage';
import SourceLeadPage from './pages/lead/SourceLeadPage';
import SourceLeadCreatePage from './pages/lead/SourceLeadCreatePage';
import LeadCountPage from './pages/report/LeadCountPage';
import LeadStatusPage from './pages/lead/LeadStatusPage';
import AddEditLeadStatusPage from './pages/lead/AddEditLeadStatusPage';
 
import { usePermission } from './contexts/PermissionContext';
import AccessDenied from './pages/common/AccessDenied';
import WelcomePage from './pages/common/Welcome';

import CreateCampaignPage from './pages/lead/CreateCampaignPage';
import CampaignPage from './pages/lead/CampaignPage';
import LeadBreakupPage from './pages/report/LeadBreakupPage';
import LeadBreakupDetailPage from './pages/report/LeadBreakupDetailPage';

import IncidentReporteCreate from './pages/incidentReporte/IncidentReporteCreate';
import IncidentReportePage from './pages/incidentReporte/IncidentReportePage';
import IncidentReportStatusPage from './pages/incidentReporte/IncidentReportStatusPage';
import IncidentReportStatusCreatePage from './pages/incidentReporte/IncidentReportStatusCreatePage';
import IncidentCategoryPage from './pages/incidentReporte/IncidentCategoryPage';
import IncidentCategoryCreatePage from './pages/incidentReporte/IncidentCategoryCreatePage';
import AllTicketPage from './pages/incidentReporte/IncidentReportListingPage';
import ViewTicket from './pages/incidentReporte/ViewTicket';

import IncidentReportGroupPage from './pages/incidentReporte/IncidentGroupPage';
import CreateIncidentReportGroupPage from './pages/incidentReporte/CreatIncidentGroupPage';
import UserReport from './pages/report/UserReport';

import PrefillTemplateNamePage from './pages/lead/PrefillTemplateNamePage';
import PrefillTemplateNameAddEdit from './pages/lead/PrefillTemplateNameAddEdit';
import UploadLeads from './pages/lead/UploadLeads';

import DocumentTypePage from './pages/lead/DocumentTypePage';
import DocumentTypeAddEdit from './pages/lead/DocumentTypeAddEdit';

import TeamsPoorReport from './pages/report/TeamsPoorReport';
import CrifReportPage from './pages/report/CrifReportPage';
import IncidentReporteCreatePage from './pages/incidentReporte/IncidentReporteCreatePage';
import LmsUsagePage from './pages/report/LmsUsagePage';
import ChangePassowordPage from './pages/login/ChangePassowordPage';
import IncidentDashboardPage from './pages/report/IncidentDashboardPage';
import ImportReportPage from './pages/crif_input/ImportReportPage';
import HabileUsagePage from './pages/report/HabileUsagePage';
import ScorecardsReportPage from './pages/report/ScorecardsReportPage';
import VisitStatusPage from './pages/beat_plan/VisitStatusPage';
import VisitStatusAddEdit from './pages/beat_plan/VisitStatusAddEdit';
import AddEditBeatPlan from './pages/beat_plan/AddEditBeatPlan';
import BeatPlanPage from './pages/beat_plan/BeatPlanPage';
import UserLocationsPage from './pages/beat_plan/UserLocationsPage';
import UserBeatPlanPage from './pages/beat_plan/UserBeatPlanPage';

import CkersFinanceCombineReport from './pages/report/CkersFinanceCombineReport';
import CrifPortfolioDashboard from './pages/dashboard/CrifPortfolioDashboard'; 
import Payment from './pages/user/Payment';
import VisitPlanPage from './pages/beat_plan/VisitPlanPage';
import RiskMatrixReportPage from './pages/report/RiskMatrixReportPage';
import ManageShipment from './pages/manage_shipment/ManageShipment';
import ManageShipmentListingPage from './pages/manage_shipment/ManageShipmentListingPage';
import BeatPlanReportPage from './pages/report/BeatPlanReportPage';
import TatReportPage from './pages/report/TatReportPage';
import ExportSalesScorePage from './pages/report/ExportSalesScorePage';

import ModuleCreatePage from './pages/module_page/ModuleCreatePage';
import ModuleListingPage from './pages/module_page/ModuleListingPage';
import ModuleViewTicketPage from './pages/module_page/ModuleViewTicketPage';

import ApprovalMasterPage from './pages/Master/ApprovalMasterPage';
import CreateApprovalMasterPage from './pages/Master/CreateApprovalMasterPage';
import SendToAllcloudPage from './pages/lead/SendToAllcloudPage';

import FiReportPage from './pages/report/FiReportPage';
import HabileApplications from './pages/lead/HabileApplications';
import AllCloudLoansReport from './pages/report/AllCloudLoansReport';
import AuditReport from './pages/report/AuditReport';
import Consent from './pages/lead/Consent';
import AuditReportWithOwnData from './pages/report/AuditReportWithOwnData';
import ModuleAccess from './pages/lead/ModuleAccess';
import LoanBookListingPage from './pages/report/LoanBookListingPage';
import SubLeadStatusListingPage from './pages/Master/SubLeadStatusListingPage';
import SubLeadStatusCreatePage from './pages/Master/SubLeadStatusCreatePage';
import AllLeadInteractionsPage from './pages/report/AllLeadInteractionsPage';

import CrifOtpsReportPage from './pages/report/CrifOtpsReportPage';

import EnachTransactionScheduling from './pages/report/EnachTransactionScheduling';
import EnachMandates from './pages/report/EnachMandates';


import AllCloudLoansReportDev from './pages/report/AllCloudLoansReportDev';
import EnachTransactionSchedulingDev from './pages/report/EnachTransactionSchedulingDev';
import EnachMandatesDev from './pages/report/EnachMandatesDev';
import Enachrecon from './pages/report/Enachrecon';

import AllCloudLoanRepayment from './pages/report/AllCloudLoanRepayment';
import AllCloudLoanRepaymentDev from './pages/report/AllCloudLoanRepaymentDev';
// ----------------------------------------------------------------------
// Create a component to handle route rendering with permissions
function ProtectedRoute({ route, ...rest }) {
  const modulePermissions = usePermission(); 

  console.log("modulePermissions = ",modulePermissions);
  console.log("route = ",route);
  console.log("module = ",route.module);

  const requiredPermissions = route.permission; 
  
  // Example: Conditionally render a link based on 'view_permission' for 'Leads' module 
   
  // Check if all required permissions are granted
 /* const hasPermissions = requiredPermissions.every((permission) =>
    modulePermissions.some((module) => module[permission])
  ); */
  let  hasPermissions = false;
  if(requiredPermissions === 'view_permission'){
      hasPermissions = modulePermissions.permissions.find((permission) => permission.module_name === route.module)?.view_permission;
  }
  if(requiredPermissions === 'edit_permission'){
      hasPermissions = modulePermissions.permissions.find((permission) => permission.module_name === route.module)?.edit_permission;
  }
  if(requiredPermissions === 'add_permission'){
      hasPermissions = modulePermissions.permissions.find((permission) => permission.module_name === route.module)?.add_permission;
  }
 // console.log("canViewLeads = ",canViewLeads); 
  console.log("hasPermissions = ",hasPermissions); 
 
  if(hasPermissions){
    alert('1111');
  }else{
    alert('2222');

  }  

  return hasPermissions ? (
    <>
     { /*   <Routes>
         <Route {...rest} component={route.element} />
      </Routes> 
      <Navigate to='/dashboard/welcome' replace /> */ }
      <Navigate to={route.path} replace /> 

    </>
  ) : (
    <Navigate to="/dashboard/access-denied" replace />
  ); 
}

export default function Router() {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { path: 'access-denied', element: <AccessDenied /> },
        { path: 'welcome', element: <WelcomePage /> },
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'user', element: <UserPage /> },
        { path: 'create-user', element: <UserCreatePage /> },
        { path: 'edit-user/:id', element: <UserCreatePage /> },
        { path: 'create-lead', element: <AddEditLeadPage /> },
        {
          path: 'create-lead-permission',
          element: (
            <ProtectedRoute 
              route={{
              path: '/dashboard/create-lead', 
              module: "Leads",
              element: <AddEditLeadPage />,
              permission: 'add_permission'
            }}
            element={<AddEditLeadPage />} 
            >
              <AddEditLeadPage />
            </ProtectedRoute>
          ) 
        },
        { path: 'create-lead-new', element: <AddEditLeadPage /> }, 
        { path: 'leads', element: <LeadsPage />},
        { path: 'edit-lead/:id', element: <AddEditLeadPage /> },
        // { path: 'create-campaign', element: <CreateCampaignPage /> },
        { path: 'campaigns', element: <CampaignPage /> },
        { path: 'lead-qualification-checklist', element: <LQCPage /> },
        { path: 'lead-qualification-checklist-new', element: <LeadChecklistForm /> },
        { path: 'reporting-engine', element: <ReportingEnginePage /> },
        { path: 'user-role', element: <UserRolePage /> },
        { path: 'department', element: <DepartmentPage /> },
        { path: 'create-department', element: <DepartmentCreatePage /> },
        { path: 'edit-department/:id', element: <DepartmentCreatePage /> },
        { path: 'create-user-role', element: <UserRoleCreatePage /> },
        { path: 'edit-role/:id', element: <UserRoleCreatePage /> },
        { path: 'role-permissions', element: <RolePermissionPage /> },
        { path: 'add-role-permission', element: <RolePermissionCreatePage /> },
        { path: 'edit-role-permission/:id', element: <RolePermissionCreatePage /> }, 
        { path: 'checklists', element: <ChecklistsPage /> },
        { path: 'create-checklist', element: <LeadQualificationChecklistCreate /> },
        { path: 'edit-checklist/:id', element: <LeadQualificationChecklistCreate /> },
        { path: 'lead-profile', element: <LeadProfilePage /> },
        { path: 'create-lead-profile', element: <LeadProfileCreatePage /> },
        { path: 'edit-lead-profile/:id', element: <LeadProfileCreatePage /> },
        { path: 'source-lead', element: <SourceLeadPage /> },
        { path: 'create-source-lead', element: <SourceLeadCreatePage /> },
        { path: 'edit-source-lead/:id', element: <SourceLeadCreatePage /> },
        { path: 'lead-count', element: <LeadCountPage /> },
        { path: 'lead-status', element: <LeadStatusPage /> },
        { path: 'create-lead-status', element: <AddEditLeadStatusPage /> },
        { path: 'edit-lead-status/:id', element: <AddEditLeadStatusPage /> },
        { path: 'create-campaign', element: <CreateCampaignPage /> },
        { path: 'edit-campaign/:id', element: <CreateCampaignPage /> },
        { path: 'lead-breakup/', element: <LeadBreakupPage /> },        
        { path: 'lead-breakup-details/:leadTypeId/:leadBreakupType/:getDate', element: <LeadBreakupDetailPage /> },
        
        { path: 'create-incident-reporting/:leadId', element: <IncidentReporteCreate /> },
        { path: 'edit-incident-reporting/:leadId/:incidentReporteId', element: <IncidentReporteCreate /> },
        { path: 'incident-reporting/:leadId', element: <IncidentReportePage /> },
        
        { path: 'incident-report-status', element: <IncidentReportStatusPage /> },
        { path: 'add-incident-report-status', element: <IncidentReportStatusCreatePage /> },
        { path: 'edit-incident-report-status/:id', element: <IncidentReportStatusCreatePage /> },

        { path: 'incident-category', element: <IncidentCategoryPage /> },
        { path: 'add-incident-category', element: <IncidentCategoryCreatePage /> },
        { path: 'edit-incident-category/:id', element: <IncidentCategoryCreatePage /> },
        { path: 'incident-report', element: <AllTicketPage /> },
        { path: 'view-ticket/:incidentReportId', element: <ViewTicket /> },

        { path: 'incident-report-user-group', element: <IncidentReportGroupPage /> },
        { path: 'edit-incident-group/:id', element: <CreateIncidentReportGroupPage /> },
        { path: 'create-incident-report-group', element: <CreateIncidentReportGroupPage /> },
        { path: 'user-report', element: <UserReport /> },

        { path: 'prefill-template-name', element: <PrefillTemplateNamePage /> },
        { path: 'create-prefill-template-name', element: <PrefillTemplateNameAddEdit /> },
        { path: 'edit-prefill-template-name/:id', element: <PrefillTemplateNameAddEdit /> },
        { path: 'upload-leads', element: <UploadLeads /> },

        { path: 'document-type', element: <DocumentTypePage /> },
        { path: 'create-document-type', element: <DocumentTypeAddEdit /> },
        { path: 'edit-document-type/:id', element: <DocumentTypeAddEdit /> },
        { path: 'planreport/:reportName', element: <TeamsPoorReport /> },
        { path: 'planreport:reportName', element: <TeamsPoorReport /> },

        { path: 'crif-report-page/', element: <CrifReportPage /> },

        { path: 'create-incident-reporting/', element: <IncidentReporteCreatePage /> },
        { path: 'edit-lead/:id/:actionName', element: <AddEditLeadPage /> },

        { path: 'lms-usage-page/', element: <LmsUsagePage /> },

        { path: 'incident-dashboard/', element: <IncidentDashboardPage /> },

        { path: 'import-report/:importName', element: <ImportReportPage /> },
        { path: 'habile-report/', element: <HabileUsagePage /> },
        { path: 'score-cards/', element: <ScorecardsReportPage /> },


        { path: 'visit-status', element: <VisitStatusPage /> },
        { path: 'create-visit-status', element: <VisitStatusAddEdit /> },
        { path: 'edit-visit-status/:id', element: <VisitStatusAddEdit /> },
        { path: 'beat-plan', element: <BeatPlanPage /> },
        { path: 'create-beat-plan', element: <AddEditBeatPlan /> },
        { path: 'edit-beat-plan/:id', element: <AddEditBeatPlan /> },
        { path: 'beat-plan-gps', element: <UserLocationsPage /> },
        { path: 'ckers-finance-combine-report', element: <CkersFinanceCombineReport /> },
        { path: 'ckers-finance-combine-report/:brief', element: <CkersFinanceCombineReport /> },
        { path: 'ckers-finance-combine-report/:brief/:param1', element: <CkersFinanceCombineReport /> },
        { path: 'due-daily-beat', element: <UserBeatPlanPage /> },
        { path: 'crif-portfolio-dashboard', element: <CrifPortfolioDashboard /> },
        { path: 'payment', element: <Payment /> },
        { path: 'visit-plan', element: <VisitPlanPage /> },
        { path: 'risk-matrix', element: <RiskMatrixReportPage /> },
        { path: 'manage-shipment', element: <ManageShipment /> },
        { path: 'all-shipments', element: <ManageShipmentListingPage /> },
        { path: 'create-shipment/:leadId', element: <ManageShipment /> },

        { path: 'beat-plan-report/', element: <BeatPlanReportPage /> },
        { path: 'tat-report/', element: <TatReportPage /> },
        { path: 'export-sales-score', element: <ExportSalesScorePage /> },
        
        { path: 'module-create-page/', element: <ModuleCreatePage /> },
        { path: 'module-listing-page', element: <ModuleListingPage /> },
        { path: 'module-view-ticket-page/:incidentReportId', element: <ModuleViewTicketPage /> },
        { path: 'module-view-ticket-page/:incidentReportId/:sectionName/:incidentTaskId', element: <ModuleViewTicketPage /> },
        { path: 'module-view-ticket-page/:incidentReportId/:sectionName', element: <ModuleViewTicketPage /> },

        { path: 'approval-master', element: <ApprovalMasterPage /> },
        { path: 'create-approval-master', element: <CreateApprovalMasterPage /> },
        { path: 'create-approval-master/:id', element: <CreateApprovalMasterPage /> },
        { path: 'send-to-allcloud/:id', element: <SendToAllcloudPage /> },
        { path: 'send-to-allcloud/:id/:urlLoanApplicationID', element: <SendToAllcloudPage /> },

        { path: 'fi-report', element: <FiReportPage /> },
        { path: 'loan-applications', element: <HabileApplications /> },
        { path: 'allcloud-loans-report', element: <AllCloudLoansReport /> },
        { path: 'allcloud-loans-report/:id', element: <AllCloudLoansReport /> },
        { path: 'allcloud-loans-report/:id/:urlAgreementNo', element: <AllCloudLoansReport /> },
        { path: 'allcloud-loans-report/:id/:urlAgreementNo/:brief', element: <AllCloudLoansReport /> },

        { path: 'audit-report', element: <AuditReport /> },
        { path: 'consent/:id/:sectionId/:sectionName', element: <Consent /> },
        { path: 'audit-report-data', element: <AuditReportWithOwnData /> },
        { path: 'loan-book', element: <LoanBookListingPage /> },
        
        { path: 'sub-lead-status', element: <SubLeadStatusListingPage /> },
        { path: 'create-sub-lead-status', element: <SubLeadStatusCreatePage /> },
        { path: 'edit-sub-lead-status/:id', element: <SubLeadStatusCreatePage /> },
        { path: 'all-lead-interactions', element: <AllLeadInteractionsPage /> },
        { path: 'crif-otps-report', element: <CrifOtpsReportPage /> },

        { path: 'module-create-page/:incidentReporteId', element: <ModuleCreatePage /> },
        { path: 'enach-transaction-scheduling', element: <EnachTransactionScheduling /> },
        { path: 'enach-transaction-scheduling/:id', element: <EnachTransactionScheduling /> },
        { path: 'enach-transaction-scheduling/:id/:urlAgreementNo', element: <EnachTransactionScheduling /> },
        { path: 'enach-transaction-scheduling/:id/:urlAgreementNo/:brief', element: <EnachTransactionScheduling /> },
        { path: 'enach-mandates', element: <EnachMandates /> }, 

        { path: 'allcloud-loans-report-dev', element: <AllCloudLoansReportDev /> },
        { path: 'allcloud-loans-report-dev/:id', element: <AllCloudLoansReportDev /> },
        { path: 'allcloud-loans-report-dev/:id/:urlAgreementNo', element: <AllCloudLoansReportDev /> },
        { path: 'allcloud-loans-report-dev/:id/:urlAgreementNo/:brief', element: <AllCloudLoansReportDev /> },
        { path: 'enach-transaction-scheduling-dev', element: <EnachTransactionSchedulingDev /> },
        { path: 'enach-transaction-scheduling-dev/:id', element: <EnachTransactionSchedulingDev /> },
        { path: 'enach-transaction-scheduling-dev/:id/:urlAgreementNo', element: <EnachTransactionSchedulingDev /> },
        { path: 'enach-transaction-scheduling-dev/:id/:urlAgreementNo/:brief', element: <EnachTransactionSchedulingDev /> },
        { path: 'enach-mandates-dev', element: <EnachMandatesDev /> },

        { path: 'enach-recon', element: <Enachrecon />},
        { path: 'allcloud-loan-repayment', element: <AllCloudLoanRepayment /> },
        { path: 'allcloud-loan-repayment-dev', element: <AllCloudLoanRepaymentDev /> },
      ], 
    }, 
    { 
      path: 'login',
      element: <LoginPage />,
    },
    { 
      path: 'consent',
      element: <Consent />,
    },
    { 
      path: 'consent/:id/:sectionId/:sectionName',
      element: <Consent />,
    },
    { 
      path: 'module-access/:userId/:incidentId/:sectionName',
      element: <ModuleAccess />,
    },
    { 
      path: 'module-access/:userId/:incidentId/:sectionName/:incidentTaskId',
      element: <ModuleAccess />,
    },
    { path: 'reset_password/:identifier', element: <ChangePassowordPage /> },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/login" />, index: true },
        { path: '404', element: <Page404 /> }, 
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
    
  ]);

  return routes;
}


 