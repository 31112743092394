export const LOGIN = "login";
export const GET_USERS = "get_users";

// User roles
export const CU_ROLE = "cu_role";
export const GET_ROLES = "get_roles";

// Role permission
export const CU_ROLE_PERMISSION = "cu_role_permission";
export const GET_ROLE_PERMISSION = "get_role_permissions"; 

// Departments
export const CU_DEPARTMENT = "cu_department";
export const GET_DEPARTMENTS = "get_departments"; 

// SOURCE OF LREADS
export const GET_SOURCE_OF_LREADS = "get_source_of_lead";

// Lead
export const CREATE_LEAD = "create_lead";
export const GET_LEAD_CHECKLIST = "get_lead_checklist";
export const CREATE_LEAD_COMMUNICATION = "create_communication";
export const GET_LEAD_PROFILE = "get_lead_profile";
export const CREATE_LEAD_QUALIFICATION_CHECKLIST = "create_lead_qualification";
export const CU_QUALIFICATION_CHECKLIST = "cu_qualification_checklist";
export const GET_LEADS = "get_leads";
export const GET_COMMUNICATION = "get_communication";
export const GET_INTERACTION_TYPE = "get_interaction_type";
export const GET_LEAD_STATUS = "get_lead_status";


export const GET_MODULES_PERMISSIONS = "get_modules_permissions";
export const SAVE_MODULES_PERMISSIONS = "save_modules_permissions";
export const GET_CHECKLISTS = "get_checklists";
export const CREATE_LEAD_PROFILE = "create_lead_profile";
export const CREATE_SOURCE_LEAD= "create_source_of_lead";

export const UPDATE_LEAD_CHECKLIST_QUESTION_ANSWER =  "update_lead_checklist_question_answer";
export const GET_USER_TYPES = "get_user_types";
export const GET_LEAD_COUNT =  "lead_count";

export const CU_USER = "cu_user";
export const CREATE_LEAD_STATUS = "create_lead_status";
export const GET_CAMPAIGN = "get_campaign";
export const CREATE_CAMPAIGN = "create_campaign";
export const GET_LEAD_BREAKUP = "get_lead_breakup";
export const GET_USER_MODULE_PERMISSION = "get_user_module_permission";
export const GET_LEAD_BREAKUP_DETAILS = "get_lead_breakup_details";
export const GET_LOGGEDIN_USER_ACTIVITY = "get_loggedin_user_activity";
export const GET_LOGGEDIN_USER_FOLLOWUPS = "get_loggedin_user_followup_leads";
export const GET_LOGGEDIN_USER_NEW_LEADS = "get_loggedin_user_new_leads";

export const CREATE_INCIDENT_REPORTE = "create_incident_reporte";
export const GET_INCIDENT_REPORTE = "get_incident_reporte";
export const GET_INCIDENT_REPORTE_STATUS = "get_incident_report_status";
export const CREATE_INCIDENT_REPORTE_STATUS = "create_incident_report_status";

export const INCIDENT_REPORTE_FILE_DOWNLOAD = "incident_report_file_download";
export const CREATE_INCIDENT_CATEGORY = "create_incident_category";
export const GET_INCIDENT_CATEGORY = "get_incident_category";
export const GET_INCIDENT_PRIORITY = "get_incident_priority";
export const GET_INCIDENT_REPORT_BY_USER = "get_incident_report_by_users";
export const GET_VIEW_TICKET = "get_incident_report_comment";
export const CREATE_INCIDENT_REPORTE_COMMENT = "create_incident_report_comment";


export const UPDATE_LEAD_PAN_DETAILS = "update_lead_pan_details";
export const ADD_CLIENT_HABILE_INFO = "add_client_habile_info";
export const ADD_IDENTIFIER_HABILE_INFO = "add_identifier_habile_info";

export const GET_GROUPS_USERS = "get_groups_users";
export const GET_INCIDENT_GROUP = "get_incident_group";
export const CREATE_INCIDENT_GROUP = "create_incident_group";

export const GET_LEAD_GST = "get_lead_gst";
export const GET_LEAD_GST_BY_LEAD = "get_lead_gst_by_lead";
export const CREATE_LEAD_GST = "create_lead_gst";

export const CREATE_CO_APPLICANT = "create_co_applicant";
export const GET_CO_APPLICANT = "get_co_applicant";
export const GET_RELATIONSHIP = "get_relationship";

export const GET_GUARANTOR = "get_guarantor";
export const CREATE_GUARANTOR = "create_guarantor";

export const CREATE_LEAD_ITR = "create_lead_itr";
export const GET_LEAD_ITR = "get_lead_itr";
export const GET_LEAD_ITR_BY_LEAD = "get_lead_itr_by_lead";

export const CREATE_LEAD_BANK_STATEMENT = "create_lead_bank_statement";
export const GET_LEAD_BANK_STATEMENT = "get_lead_bank_statement";
export const GET_LEAD_BANK_STATEMENT_BY_LEAD = "get_lead_bank_statement_by_lead";

export const GET_IDENTIFIERS = "get_identifiers";
export const ADD_SUMMARY_INFO = "add_summary_info";
export const CALL_CRIF_API = "call_crif_api";

export const GET_STATES = "get_states";
export const ADD_CREDIT = "add_credit";
export const GET_NOTE_HISTORY = "get_note_history";
export const GET_ROLE_TYPE = "get_role_type";
export const GET_USER_REPORT = "get_user_report";

export const GET_PREFILL_TEMPLATE_TYPE = "get_prefill_template_type";
export const GET_PREFILL_TEMPLATE_NAME = "get_prefill_template_name";
export const CREATE_PREFILL_TEMPLATE_NAME = "create_prefill_template_name";
export const DELETE_LEAD_BASIC_INFO = "delete_lead_basic_info";
export const GET_USER_NOTIFICATIONS = "get_user_notifications";
export const READ_USER_NOTIFICATIONS = "read_user_notifications";
export const UPLOAD_LEADS = "upload_leads";
export const GET_UPLOAD_LEAD = "get_upload_lead";
export const GET_DOCUMENT_TYPE = "get_document_type";
export const CREATE_DOCUMENT_TYPE = "create_document_type";
export const GET_UPLOAD_LEAD_DOCUMENT = "get_upload_lead_document";
export const UPLOAD_LEAD_DOCUMENT = "upload_lead_document";
export const VIEW_CRIF_REPORT = "view_crif_report";
export const GET_LOGGEDIN_USER_CHILD = "get_loggedin_user_child";
export const GET_CRIFS_DATA = "get_crifs";
export const CHECK_LEAD_BY_MOBILE = "check_lead";
export const INCIDENT_REPORTE_ASSIGNED_ME = "incident_report_assigned_me";
export const GET_LOGGEDIN_USER_SOURCE_LEADS = "get_loggedin_user_source_leads";
export const LMS_USAGE_ACTIVITY = "lms_usage_activity";
export const LOG_OUT = "log_out";
export const CRIF_USAGE_SEND_EMAIL = "crif_usage_send_email";
export const LMS_USAGE_SEND_EMAIL = "lms_usage_send_email";
export const USER_RESET_PASSWORD_SEND_EMAIL = "user_reset_password_send_email";
export const UPDATE_PASSWORD = "update_password";
export const INCIDENT_DASHBOARD = "incident_dashboard";
export const EXPORT_LEAD_SCORE_CARD = "export_lead_score_card";
export const LMS_HABILE_USAGE = "lms_habile_usage";
export const STORE_SCORE_DATA = "store_score_data";
export const GENERATED_SCORE_CARD = "generated_score_card";
export const GET_LOGGEDIN_USER_SCORECARD_ACTIVITY = "get_loggedin_user_scorecard_activity";
export const GET_DAILY_LEADS_ACTIVITY = "get_daily_leads_activity";
export const GET_VISIT_STATUS = "get_visit_status";
export const CREATE_VISIT_STATUS = "create_visit_status";
export const CREATE_BEAT_PLAN = "create_beat_plan";
export const GET_BEAT_PLAN = "get_beat_plan";
export const GET_TODAY_BEAT_PLAN = "get_today_beat_plan";
export const GET_USER_LOCATION = "get_user_locations";
export const UPDATE_INCIDENT_CHECKLIST_ANSWER =  "update_incident_checklist_answer";
export const SET_BEAT_PLAN_STATUS =  "set_beat_plan_status";
export const CREATE_INCIDENT_TASK =  "create_incident_task";
export const INCIDENT_TASK_ASSIGNED_ME =  "incident_task_assigned_me";
export const GET_USER_BEAT_PLAN = "get_user_beat_plan";
export const GET_CRIF_PORTFOLIO_DATA = "get_crif_portfolio_data";
export const GET_TASK_FOR_CALENDER = "get_task_for_calender";
export const INCIDENT_TASK_DELETE = "incident_task_delete"
export const PAYSPRINT_GENERATE_QR_CODE = "paysprint_generate_qr_code";
export const GET_VISIT_PLAN = "get_visit_plan";
export const CREATE_SHIPMENT_ORDER = "create_shipment_order";
export const GET_SHIPMENTS = "get_shipments";
export const SHIPMENT_TRACKING_AWB_NUMBER = "shipment_tracking_awb_number";
export const CANCEL_ORDER = "cancel_order";
export const BEAT_PLAN_REPORT = "beat_plan_report";
export const GET_BEAT_PLAN_REPORT_CLICK_ON_COUNT = "get_beat_plan_report_click_on_count";
export const GET_TAT_REPORT = "get_tat_report";
export const GET_TAT_REPORT_BY_USER = "get_tat_report_by_user";
export const SCORE_CARD_EXPORT = "score_card_export";

export const CREATE_MODULE = "create_module";
export const GET_MODULE = "get_module";
export const GET_MODULE_BY_USER = "get_module_by_users";
export const CHECK_MODULE_COUNT = "check_module_count";
export const CHECK_MODULE_DATA_WITH_COMMENT = "get_module_data_with_comment";
export const ADD_COMMENT_IN_MODULE = "add_comment_in_module";
export const UPDATE_MODULE_CHECKLIST_ANSWER = "update_module_checklist_answer";
export const CREATE_MODULE_TASK = "create_module_task";
export const MODULE_TASK_DELETE = "module_task_delete";
export const MODULE_ASSIGNED_ME = "module_assigned_me";
export const MODULE_TASK_ASSIGNED_ME = "module_task_assigned_me";

export const GET_USER_BY_ROLE = "get_user_by_role";
export const CREATE_APPROVAL_MASTER = "create_approval_master";
export const GET_APPROVAL_MASTER = "get_approval_master";
export const MODULE_TASK_ACTIVITY = "module_task_activity";
export const MANAGE_LEAD_ALLCLOUD_CUSTOMER = "manage_lead_allcloud_customer";

export const INCIDENT_TASK_ACTIVITY = "incident_task_activity";
export const GET_ALLCLOUD_SCHEMES = "get_allcloud_schemes";
export const GET_ALLCLOUD_LOAN_CATEGORIES = "get_allcloud_loan_categories";
export const GET_ALLCLOUD_LEAD_SOURCES = "get_allcloud_lead_sources";
export const MANAGE_ALLCLOUD_CUSTOMER_LEAD = "manage_allcloud_customer_lead";
export const GET_ALLCLOUD_ORGANIZATION_TYPES = "get_allcloud_organization_types";
export const GET_ALLCLOUD_PRODUCT_TYPES = "get_allcloud_product_types";
export const GET_ALLCLOUD_LPC_TYPES = "get_allcloud_lpc_types";
export const GET_ALLCLOUD_EMPLOYMENT_TYPES = "get_allcloud_employment_types";
export const MANAGE_ALLCLOUD_CUSTOMER_LOAN = "manage_allcloud_customer_loan";

export const GET_LEAD_SCORE_USER_CREDIT = "get_lead_score_user_credit";
export const GET_LEADS_FI_REPORTED = "get_leads_fi_reported";
export const GET_TAT_REPORT_FILTERED = "get_tat_report_filtered";

export const GET_HABILE_LOAN_APPLICATIONS = "get_habile_loan_applications";
export const GET_ALLCLOUD_LOANSEGMENTS = "get_allcloud_loansegments";
export const GET_BUSINESS_DONE_LOANS = "get_business_done_loans";
export const VALIDATE_BEFISC_PHONE_DETAILS = "validate_befisc_phone_details";
export const GET_AUDIT_REPORT_BY_DATES = "audit_report_by_dates";
export const GET_AUDIT_REPORT_DATA = "audit_report_data";
export const EXPORT_AUDIT_REPORT_DATA = "exort_audit_report_data";
export const SEND_ENACH_REGISTRATION_DETAILS = "send_enach_registration_details";
export const UPDATE_USER_ACTIVE_ROLE = "update_user_active_role";
export const MODULE_REDIRECT = "module_access";
export const ADD_EDIT_ADDITIONAL_MISSING_DATA = "add_edit_additional_missing_data";
export const UPLOAD_MISSING_DATA = "upload_missing_data";
export const GET_ALL_ADDITIONAL_MISSING_DATA = "get_all_additional_missing_data";
export const GOOGLEAUTH = "googleauth";
export const GET_LOAN_BOOK_DATA = "get_loan_book_data";
export const ADD_EDIT_SUB_LEAD_STATUS = "add_edit_sub_lead_status";
export const GET_SUB_LEAD_STATUS = "get_sub_lead_status";
export const GET_ALL_LEAD_INTERACTIONS = "get_all_leads_interactions";
export const GET_LOGGEDIN_USER_EXTERNAL_LEADS = "get_loggedin_user_external_leads";
export const CRIF_OTPS = "crif_otps";
export const MODULE_DELETE = "module_delete";
export const ENACH_ACTIONS_DETAILS = "enach_actions_details";

export const GET_ENACH_MANDATES = "get_enach_mandates";
export const GET_MODE_OF_TRANSPORT = "get_mode_of_transport";
export const GET_OEM = "get_oem";
export const GET_OEM_USAGE = "get_oem_usage";
export const MODULE_RESTORE = "module_restore";
export const UPDATE_FI_CHECKLIST_QUESTION_ANSWER = "update_fi_checklist_question_answer";

export const GET_LOANS_CLEARANCE = "get_loans_clearance";


