import PropTypes from 'prop-types';
import { NavLink, NavLink as RouterLink, useNavigate } from 'react-router-dom';
import { useState, useEffect, useCallback } from 'react';
import $ from 'jquery';
// @mui
import { Box, List, ListItemText } from '@mui/material';
//
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import config from "../../config";
import { encryptData } from '../../utils/cryptoUtils';
import * as userActions from "../../actions/userActions";
import { StyledNavItem, StyledNavItemIcon } from './styles';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
// ----------------------------------------------------------------------

/* 
NavSection.propTypes = {
  data: PropTypes.array,
}; */

const NavSection = (props, { data = [], ...other }) => {
  const { actions, getUserModuleData, userInfo } = props;

  const navigate = useNavigate();

  const [viewLeadModulePermissionStatus, setViewLeadModulePermissionStatus] = useState(false);
  const [viewUserModulePermissionStatus, setViewUserModulePermissionStatus] = useState(false);
  const [viewMastersModulePermissionStatus, setViewMastersModulePermissionStatus] = useState(false);
  const [viewReportsModulePermissionStatus, setViewReportsModulePermissionStatus] = useState(false);
  const [viewIncidentReportPermissionStatus, setIncidentReportPermissionStatus] = useState(false);

  const [viewTeamsPoorModulePermissionStatus, setViewTeamsPoorModulePermissionStatus] = useState(false);
  const [viewBeatPlanModulePermissionStatus, setViewBeatPlanModulePermissionStatus] = useState(false);
  
  const [viewDepartmentModulePermissionStatus, setViewDepartmentModulePermissionStatus] = useState(false);
  const [viewChecklistsModulePermissionStatus, setViewChecklistsModulePermissionStatus] = useState(false);
  const [viewUserRoleModulePermissionStatus, setViewUserRoleModulePermissionStatus] = useState(false);
  const [viewLeadProfileModulePermissionStatus, setViewLeadProfileModulePermissionStatus] = useState(false);
  const [viewSourceOfLeadModulePermissionStatus, setViewSourceOfLeadModulePermissionStatus] = useState(false);
  const [viewLeadStatusModulePermissionStatus, setViewLeadStatusModulePermissionStatus] = useState(false);
  const [viewCampaignsModulePermissionStatus, setViewCampaignsModulePermissionStatus] = useState(false);
  const [viewIncidentReportStatusModulePermissionStatus, setViewIncidentReportStatusModulePermissionStatus] = useState(false);
  const [viewIncidentCategoryModulePermissionStatus, setViewIncidentCategoryModulePermissionStatus] = useState(false);
  const [viewUserGroupModulePermissionStatus, setViewUserGroupModulePermissionStatus] = useState(false);
  const [viewPrefillTemplateModulePermissionStatus, setViewPrefillTemplateModulePermissionStatus] = useState(false);
  const [viewDocumentTypeModulePermissionStatus, setViewDocumentTypeModulePermissionStatus] = useState(false);
  const [viewVisitStatusModulePermissionStatus, setViewVisitStatusModulePermissionStatus] = useState(false);
  const [viewUserReportModulePermissionStatus, setViewUserReportModulePermissionStatus] = useState(false);
  const [viewLeadCountModulePermissionStatus, setViewLeadCountModulePermissionStatus] = useState(false);
  const [viewLeadBreakupModulePermissionStatus, setViewLeadBreakupModulePermissionStatus] = useState(false);
  const [viewCRIFUsageModulePermissionStatus, setViewCRIFUsageModulePermissionStatus] = useState(false);
  const [viewLMSUsageModulePermissionStatus, setViewLMSUsageModulePermissionStatus] = useState(false);
  const [viewIncidentDashboardModulePermissionStatus, setViewIncidentDashboardModulePermissionStatus] = useState(false);
  const [viewHabileUsageModulePermissionStatus, setViewHabileUsageModulePermissionStatus] = useState(false);
  const [viewScorecardsModulePermissionStatus, setViewScorecardsModulePermissionStatus] = useState(false);
  const [viewRiskMatrixModulePermissionStatus, setViewRiskMatrixModulePermissionStatus] = useState(false);
  const [viewManageShipmentModulePermissionStatus, setViewManageShipmentModulePermissionStatus] = useState(false);
  const [beatPlanReportModulePermissionStatus, setBeatPlanReportModulePermissionStatus] = useState(false);
  const [tatReportModulePermissionStatus, setTatReportModulePermissionStatus] = useState(false);
  const [exportSalesScoreModulePermissionStatus, setExportSalesScoreModulePermissionStatus] = useState(false);
  const [requestModulePermissionStatus, setRequestModulePermissionStatus] = useState(false);
  const [viewApprovalMasterModulePermissionStatus, setApprovalMasterModulePermissionStatus] = useState(false);
  const [viewFIReportModulePermissionStatus, setFIReportModulePermissionStatus] = useState(false);
  const [viewAuditReportModulePermissionStatus, setAuditReportModulePermissionStatus] = useState(false);
  const [viewUploadLeadModulePermissionStatus, setUploadLeadModulePermissionStatus] = useState(false);
  const [viewLoanBookModulePermissionStatus, setLoanBookModulePermissionStatus] = useState(false);
  const [viewSubLeadStatusModulePermissionStatus, setSubLeadStatusModulePermissionStatus] = useState(false);
  const [viewOTPOverrideModulePermissionStatus, setOTPOverrideModulePermissionStatus] = useState(false);
  const [vieweNachReconModulePermissionStatus, seteNachReconModulePermissionStatus] = useState(false);
  const [vieweNachPresentationModulePermissionStatus, seteNachPresentationModulePermissionStatus] = useState(false);
  

  const [userRoleType, setUserRoleType] = useState('User');
  const [loggeInUserID, setLoggeInUserID] = useState('');

  useEffect(() => {
    // check user is logged-in or not
    const authToken = localStorage.getItem('authToken');
    if(authToken === null || authToken === ""){
      navigate('/login');
    }
  }, []);

  useEffect(() => {
    const obj = { 'start': '0', 'length': config.MAX_RECORDS };
    const requestData = encryptData(obj);
    const response = actions.getUserModuleData(requestData);
    response.then((result) => {
      // console.log("Promise resolved -  lead list:", result);
      if (result.data.success === 1) {
        const permissionsData = result.data.data;

        console.log("nav permissionsData = ", permissionsData);

        // Leads module permission
        const leadPermissions = permissionsData.find(permission => permission.module_name === 'Leads');
        const hasViewPermissions = leadPermissions?.view_permission;
        setViewLeadModulePermissionStatus(hasViewPermissions);

        // Users module permission
        const userPermissions = permissionsData.find(permission => permission.module_name === 'Users');
        const hasViewUserPermissions = userPermissions?.view_permission;
        setViewUserModulePermissionStatus(hasViewUserPermissions);

        // Masters module permission
        const mastersPermissions = permissionsData.find(permission => permission.module_name === 'Masters');
        const hasViewMastersPermissions = mastersPermissions?.view_permission;
        setViewMastersModulePermissionStatus(hasViewMastersPermissions);


        // Reports module permission
        const reportsPermissions = permissionsData.find(permission => permission.module_name === 'Reports');
        const hasViewReportsPermissions = reportsPermissions?.view_permission;
        setViewReportsModulePermissionStatus(hasViewReportsPermissions);

        const incidentReportPermissions = permissionsData.find(permission => permission.module_name === 'Incident Report');
        const hasPermissionsIncidentReportPermissions = incidentReportPermissions?.view_permission;
        setIncidentReportPermissionStatus(hasPermissionsIncidentReportPermissions);

        // MastTeamspoorers module permission
        const teamspoorPermissions = permissionsData.find(permission => permission.module_name === 'Teamspoor');
        const hasViewTeamspoorPermissions = teamspoorPermissions?.view_permission;
        setViewTeamsPoorModulePermissionStatus(hasViewTeamspoorPermissions);

        // MastTeamspoorers module permission
        const beatPlanPermissions = permissionsData.find(permission => permission.module_name === 'Beat Plan');
        const hasViewBeatPlanPermissions = beatPlanPermissions?.view_permission;
        setViewBeatPlanModulePermissionStatus(hasViewBeatPlanPermissions);

        const hasChecklistsViewPermissions = permissionsData.find(permission => permission.module_name === 'Checklists')?.view_permission;
        setViewChecklistsModulePermissionStatus(hasChecklistsViewPermissions);

        const hasDepartmentViewPermissions = permissionsData.find(permission => permission.module_name === 'Departments')?.view_permission;
        setViewDepartmentModulePermissionStatus(hasDepartmentViewPermissions);

        const hasUserRoleViewPermissions = permissionsData.find(permission => permission.module_name === 'User Role')?.view_permission;
        setViewUserRoleModulePermissionStatus(hasUserRoleViewPermissions);

        const hasLeadProfileViewPermissions = permissionsData.find(permission => permission.module_name === 'Lead Profile')?.view_permission;
        setViewLeadProfileModulePermissionStatus(hasLeadProfileViewPermissions);

        const hasSourceOfLeadViewPermissions = permissionsData.find(permission => permission.module_name === 'Source Of Lead')?.view_permission;
        setViewSourceOfLeadModulePermissionStatus(hasSourceOfLeadViewPermissions);

        const hasLeadStatusViewPermissions = permissionsData.find(permission => permission.module_name === 'Lead Status')?.view_permission;
        setViewLeadStatusModulePermissionStatus(hasLeadStatusViewPermissions);

        const hasCampaignsViewPermissions = permissionsData.find(permission => permission.module_name === 'Campaigns')?.view_permission;
        setViewCampaignsModulePermissionStatus(hasCampaignsViewPermissions);

        const hasIncidentReportStatusViewPermissions = permissionsData.find(permission => permission.module_name === 'Incident Report Status')?.view_permission;
        setViewIncidentReportStatusModulePermissionStatus(hasIncidentReportStatusViewPermissions);

        const hasIncidentCategoryViewPermissions = permissionsData.find(permission => permission.module_name === 'Incident Category')?.view_permission;
        setViewIncidentCategoryModulePermissionStatus(hasIncidentCategoryViewPermissions);

        const hasUserGroupViewPermissions = permissionsData.find(permission => permission.module_name === 'User Group')?.view_permission;
        setViewUserGroupModulePermissionStatus(hasUserGroupViewPermissions);

        const hasPrefillTemplateViewPermissions = permissionsData.find(permission => permission.module_name === 'Prefill Template')?.view_permission;
        setViewPrefillTemplateModulePermissionStatus(hasPrefillTemplateViewPermissions);

        const hasDocumentTypeViewPermissions = permissionsData.find(permission => permission.module_name === 'Document Type')?.view_permission;
        setViewDocumentTypeModulePermissionStatus(hasDocumentTypeViewPermissions);

        const hasVisitStatusViewPermissions = permissionsData.find(permission => permission.module_name === 'Visit Status')?.view_permission;
        setViewVisitStatusModulePermissionStatus(hasVisitStatusViewPermissions);

        const hasUserReportViewPermissions = permissionsData.find(permission => permission.module_name === 'User Report')?.view_permission;
        setViewUserReportModulePermissionStatus(hasUserReportViewPermissions);

        const hasLeadCountViewPermissions = permissionsData.find(permission => permission.module_name === 'Lead Count')?.view_permission;
        setViewLeadCountModulePermissionStatus(hasLeadCountViewPermissions);

        const hasLeadBreakupViewPermissions = permissionsData.find(permission => permission.module_name === 'Lead Breakup')?.view_permission;
        setViewLeadBreakupModulePermissionStatus(hasLeadBreakupViewPermissions);

        const hasCRIFUsageViewPermissions = permissionsData.find(permission => permission.module_name === 'CRIF Usage')?.view_permission;
        setViewCRIFUsageModulePermissionStatus(hasCRIFUsageViewPermissions);

        const hasLMSUsageViewPermissions = permissionsData.find(permission => permission.module_name === 'LMS Usage')?.view_permission;
        setViewLMSUsageModulePermissionStatus(hasLMSUsageViewPermissions);

        const hasIncidentDashboardViewPermissions = permissionsData.find(permission => permission.module_name === 'Incident Dashboard')?.view_permission;
        setViewIncidentDashboardModulePermissionStatus(hasIncidentDashboardViewPermissions);

        const hasHabileUsageViewPermissions = permissionsData.find(permission => permission.module_name === 'Habile Usage')?.view_permission;
        setViewHabileUsageModulePermissionStatus(hasHabileUsageViewPermissions);

        const hasScorecardsViewPermissions = permissionsData.find(permission => permission.module_name === 'Scorecards')?.view_permission;
        setViewScorecardsModulePermissionStatus(hasScorecardsViewPermissions);

        const hasRiskMatrixViewPermissions = permissionsData.find(permission => permission.module_name === 'Risk Matrix')?.view_permission;
        setViewRiskMatrixModulePermissionStatus(hasRiskMatrixViewPermissions);

        const hasManageShipmentViewPermissions = permissionsData.find(permission => permission.module_name === 'Manage Shipment')?.view_permission;
        setViewManageShipmentModulePermissionStatus(hasManageShipmentViewPermissions);

        const hasBeatPlanReportViewPermissions = permissionsData.find(permission => permission.module_name === 'Beat Plan Report')?.view_permission;
        setBeatPlanReportModulePermissionStatus(hasBeatPlanReportViewPermissions);

        const hasTatReportViewPermissions = permissionsData.find(permission => permission.module_name === 'TAT Report')?.view_permission;
        setTatReportModulePermissionStatus(hasTatReportViewPermissions);

        const hasExportSalesScoreViewPermissions = permissionsData.find(permission => permission.module_name === 'Export Sales Score')?.view_permission;
        setExportSalesScoreModulePermissionStatus(hasExportSalesScoreViewPermissions);

        const hasRequestViewPermissions = permissionsData.find(permission => permission.module_name === 'Request')?.view_permission;
        setRequestModulePermissionStatus(hasRequestViewPermissions);

        const hasApprovalMasterPermissions = permissionsData.find(permission => permission.module_name === 'Approval Master')?.view_permission;
        setApprovalMasterModulePermissionStatus(hasApprovalMasterPermissions);

        const hasFIReportViewPermissions = permissionsData.find(permission => permission.module_name === 'FI Report')?.view_permission;
        setFIReportModulePermissionStatus(hasFIReportViewPermissions);

        const hasAuditReportViewPermissions = permissionsData.find(permission => permission.module_name === 'Audit Report')?.view_permission;
        setAuditReportModulePermissionStatus(hasAuditReportViewPermissions);

        const hasUploadLeadViewPermissions = permissionsData.find(permission => permission.module_name === 'Upload Lead')?.view_permission;
        setUploadLeadModulePermissionStatus(hasUploadLeadViewPermissions);

        const hasLoanBookViewPermissions = permissionsData.find(permission => permission.module_name === 'Loan Book')?.view_permission;
        setLoanBookModulePermissionStatus(hasLoanBookViewPermissions);

        const hasSubLeadStatusViewPermissions = permissionsData.find(permission => permission.module_name === 'Sub Lead Status')?.view_permission;
        setSubLeadStatusModulePermissionStatus(hasSubLeadStatusViewPermissions);

        const hasOTPOverrideViewPermissions = permissionsData.find(permission => permission.module_name === 'OTP Override')?.view_permission;
        setOTPOverrideModulePermissionStatus(hasOTPOverrideViewPermissions);

        const haseNachReconViewPermissions = permissionsData.find(permission => permission.module_name === 'eNach Recon')?.view_permission;
        seteNachReconModulePermissionStatus(haseNachReconViewPermissions);

        const haseNachPresentationViewPermissions = permissionsData.find(permission => permission.module_name === 'eNach Presentation')?.view_permission;
        seteNachPresentationModulePermissionStatus(haseNachPresentationViewPermissions);

        if (Object.prototype.hasOwnProperty.call(result.data, 'user_info') && Object.prototype.hasOwnProperty.call(result.data.user_info[0], 'role_type') && result.data.user_info[0].role_type !== '') {
          setUserRoleType(result.data.user_info[0].role_type);
          // alert(result.data.user_info[0].user_id);
          setLoggeInUserID(result.data.user_info[0].user_id);
        }

      }
    }).catch((error) => {
      // console.error("Promise rejected -  lead list:", error);
    });
  }, [setViewLeadModulePermissionStatus, setViewUserModulePermissionStatus, setViewMastersModulePermissionStatus, setViewReportsModulePermissionStatus, setIncidentReportPermissionStatus, setUserRoleType, setLoggeInUserID, setViewDepartmentModulePermissionStatus, setViewChecklistsModulePermissionStatus, setViewUserRoleModulePermissionStatus, setViewLeadProfileModulePermissionStatus, setViewSourceOfLeadModulePermissionStatus, setViewLeadStatusModulePermissionStatus, setViewCampaignsModulePermissionStatus, setViewIncidentReportStatusModulePermissionStatus, setViewIncidentCategoryModulePermissionStatus, setViewUserGroupModulePermissionStatus, setViewPrefillTemplateModulePermissionStatus, setViewDocumentTypeModulePermissionStatus, setViewVisitStatusModulePermissionStatus, setViewUserReportModulePermissionStatus, setViewLeadCountModulePermissionStatus, setViewLeadBreakupModulePermissionStatus, setViewCRIFUsageModulePermissionStatus, setViewLMSUsageModulePermissionStatus, setViewIncidentDashboardModulePermissionStatus, setViewHabileUsageModulePermissionStatus, setViewScorecardsModulePermissionStatus, setViewRiskMatrixModulePermissionStatus, setExportSalesScoreModulePermissionStatus, setRequestModulePermissionStatus, setFIReportModulePermissionStatus]);


  useEffect(() => {

    $(".no-dropdown").click(function () {
      $(this).addClass("");
      $('.dropdown-toggle').addClass("collapsed");
      $('.dropdown-toggle').attr("aria-expanded", "false");
      $('.nav-collapse').removeClass("show");
    });

    if ($('.nav_link').hasClass('active')) {
      $('.active').parent().parent().addClass('show');
      $('.active').parent().parent().siblings().removeClass('collapsed');
    }

    $(".toggle-btn").click(function () {
      $(this).toggleClass("active");
      $('nav.MuiBox-root').toggle('fast');
      $('.toggle-btn .bi').toggleClass('d-none');
      $('header.MuiPaper-root').toggleClass('w-100');
      $('.css-1oqqzyl-MuiContainer-root').toggleClass('css-1oqqzyl-MuiContainer-root-active');
    });

  }, []);

  let appUrl = config.APP_URL;
  appUrl = appUrl.replace('/public/', '/');

  return (
    <Box {...other}>

      <nav className="nav">
        <div className="bg-light nav-profile-name px-3 py-2 rounded mb-3">
          <span>{userInfo.id ? (userInfo.name) : ("")}</span>
        </div>
        <div id="mainNav">
          <div className="nav_list no-dropdown">
            <RouterLink to="/dashboard/app" className="nav_link">
              <i className="nav-icon bi bi-box">&nbsp;</i>
              <span className="nav_name">Dashboard</span>
            </RouterLink>
          </div>

          {/* Collapse */}

          {(viewUserModulePermissionStatus) && (
            <div className="nav_list">
              <a className="nav_link dropdown-toggle collapsed" href="#usersMenu" role="button" data-bs-toggle="collapse" data-bs-target="#usersMenu" aria-expanded="false" aria-controls="usersMenu">
                <i className="bi-person nav-icon">&nbsp;</i>
                <span className="nav_link-title">Users</span>
              </a>

              <div id="usersMenu" className="nav-collapse collapse ps-3" data-bs-parent="#mainNav">
                <div className="nav_list">
                  <RouterLink to="/dashboard/create-user" className="nav_link">
                    <i className="nav-icon bi bi-person-plus">&nbsp;</i>
                    <span className="nav_name">Create User</span>
                  </RouterLink>
                </div>
                <div className="nav_list">
                  <RouterLink to="/dashboard/user" className="nav_link">
                    <i className="nav-icon bi bi-people">&nbsp;</i>
                    <span className="nav_name">Users</span>
                  </RouterLink>
                </div>
              </div>
            </div>
          )}
          {/* Collapse End */}



          {/* Collapse */}
          {(viewLeadModulePermissionStatus) && (
            <div className="nav_list">
              <a className="nav_link dropdown-toggle collapsed" href="#leadsMenu" role="button" data-bs-toggle="collapse" data-bs-target="#leadsMenu" aria-expanded="false" aria-controls="leadsMenu">
                <i className="nav-icon bi bi-collection">&nbsp;</i>
                <span className="nav_link-title">Leads</span>
              </a>

              <div id="leadsMenu" className="nav-collapse collapse ps-3" data-bs-parent="#mainNav">
                <div className="nav_list">
                  <RouterLink to="/dashboard/create-lead" className="nav_link">
                    <i className="nav-icon bi bi-folder-plus">&nbsp;</i>
                    <span className="nav_name">Create Lead</span>
                  </RouterLink>
                </div>
                <div className="nav_list">
                  <RouterLink to="/dashboard/leads" className="nav_link">
                    <i className="nav-icon bi bi-collection">&nbsp;</i>
                    <span className="nav_name">Leads</span>
                  </RouterLink>
                </div>

                {(viewUploadLeadModulePermissionStatus) && (
                  <div className="nav_list">
                    <RouterLink to="/dashboard/upload-leads" className="nav_link">
                      <i className="nav-icon bi bi-upload">&nbsp;</i>
                      <span className="nav_name">Upload Leads</span>
                    </RouterLink>
                  </div>
                )}

                { /*  <div className="nav_list"> 
                    <RouterLink to="/dashboard/create-lead-permission" className="nav_link"> 
                      <i className="nav-icon bi bi-clipboard-check">&nbsp;</i>
                      <span className="nav_name">Create Lead New</span>
                    </RouterLink>
            </div> */ }
              </div>
            </div>
          )}

          {/* Collapse End */}

          {/* Collapse */}
          {(viewManageShipmentModulePermissionStatus) && (
            <div className="nav_list d-none">
              <a className="nav_link dropdown-toggle collapsed" href="#manageShipmentMenu" role="button" data-bs-toggle="collapse" data-bs-target="#manageShipmentMenu" aria-expanded="false" aria-controls="manageShipmentMenu">
                <i className="nav-icon bi bi-collection">&nbsp;</i>
                <span className="nav_link-title">Manage Shipment</span>
              </a>

              <div id="manageShipmentMenu" className="nav-collapse collapse ps-3" data-bs-parent="#mainNav">
                <div className="nav_list">
                  <RouterLink to="/dashboard/manage-shipment" className="nav_link">
                    <i className="nav-icon bi bi-rocket-takeoff">&nbsp;</i>
                    <span className="nav_name">Create Shipment</span>
                  </RouterLink>
                </div>

                <div className="nav_list">
                  <RouterLink to="/dashboard/all-shipments" className="nav_link">
                    <i className="nav-icon bi bi-rocket-takeoff">&nbsp;</i>
                    <span className="nav_name">All Shipments</span>
                  </RouterLink>
                </div>

              </div>
            </div>
          )}

          {/* Collapse End */}

          <div className="nav_list hide">
            <RouterLink to="/dashboard/reporting-engine" className="nav_link">
              <i className="nav-icon bi bi-file-earmark-bar-graph">&nbsp;</i>
              <span className="nav_name">Reporting Engine</span>
            </RouterLink>
          </div>

          {/* Collapse */}
          {(viewReportsModulePermissionStatus) && (
            <div className="nav_list">
              <a className="nav_link dropdown-toggle collapsed" href="#reportsMenu" role="button" data-bs-toggle="collapse" data-bs-target="#reportsMenu" aria-expanded="false" aria-controls="reportsMenu">
                <i className="nav-icon bi bi-clipboard-data">&nbsp;</i>
                <span className="nav_link-title">Reports</span>
              </a>

              <div id="reportsMenu" className="nav-collapse collapse ps-3" data-bs-parent="#mainNav">

                {(viewUserReportModulePermissionStatus) && (
                  <div className="nav_list">
                    <RouterLink to="/dashboard/user-report" className="nav_link">
                      <i className="nav-icon bi bi-columns-gap">&nbsp;</i>
                      <span className="nav_name">Leads Tracker</span>
                    </RouterLink>
                  </div>
                )}

                {(viewLeadCountModulePermissionStatus) && (
                  <div className="nav_list">
                    <RouterLink to="/dashboard/lead-count" className="nav_link">
                      <i className="nav-icon bi bi-person-vcard">&nbsp;</i>
                      <span className="nav_name">Lead Count</span>
                    </RouterLink>
                  </div>
                )}

                {(viewLeadBreakupModulePermissionStatus) && (
                  <div className="nav_list">
                    <RouterLink to="/dashboard/lead-breakup" className="nav_link">
                      <i className="nav-icon bi bi-columns-gap">&nbsp;</i>
                      <span className="nav_name">Lead Breakup</span>
                    </RouterLink>
                  </div>
                )}

                {(viewCRIFUsageModulePermissionStatus) && (
                  <div className="nav_list">
                    <RouterLink to="/dashboard/crif-report-page" className="nav_link">
                      <i className="nav-icon bi bi-distribute-vertical">&nbsp;</i>
                      <span className="nav_name">CRIF Usage</span>
                    </RouterLink>
                  </div>
                )}

                {(viewLMSUsageModulePermissionStatus) && (
                  <div className="nav_list">
                    <RouterLink to="/dashboard/lms-usage-page" className="nav_link">
                      <i className="nav-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-opencollective" viewBox="0 0 16 16">
                          <path fillOpacity=".4" d="M12.995 8.195c0 .937-.312 1.912-.78 2.693l1.99 1.99c.976-1.327 1.6-2.966 1.6-4.683 0-1.795-.624-3.434-1.561-4.76l-2.068 2.028c.468.781.78 1.679.78 2.732h.04Z" />
                          <path d="M8 13.151a4.995 4.995 0 1 1 0-9.99c1.015 0 1.951.273 2.732.82l1.95-2.03a7.805 7.805 0 1 0 .04 12.449l-1.951-2.03a5.072 5.072 0 0 1-2.732.781z" />
                        </svg>
                      </i>
                      <span className="nav_name">LMS Usage</span>
                    </RouterLink>
                  </div>
                )}

                {(viewIncidentDashboardModulePermissionStatus) && (
                  <div className="nav_list">
                    <RouterLink to="/dashboard/incident-dashboard" className="nav_link">
                      <i className="nav-icon bi bi-columns-gap">&nbsp;</i>
                      <span className="nav_name">Incident Dashboard</span>
                    </RouterLink>
                  </div>
                )}

                {(viewHabileUsageModulePermissionStatus) && (
                  <div className="nav_list">
                    <RouterLink to="/dashboard/habile-report" className="nav_link">
                      <i className="nav-icon bi bi-pie-chart">&nbsp;</i>
                      <span className="nav_name">Habile Usage</span>
                    </RouterLink>
                  </div>
                )}

                {(viewScorecardsModulePermissionStatus) && (
                  <div className="nav_list">
                    <RouterLink to="/dashboard/score-cards" className="nav_link">
                      <i className="nav-icon bi bi-view-list">&nbsp;</i>
                      <span className="nav_name">Scorecards</span>
                    </RouterLink>
                  </div>
                )}

                {(beatPlanReportModulePermissionStatus) && (
                  <div className="nav_list">
                    <RouterLink to="/dashboard/beat-plan-report" className="nav_link">
                      <i className="nav-icon bi bi-columns-gap">&nbsp;</i>
                      <span className="nav_name">Beat Plan Report</span>
                    </RouterLink>
                  </div>
                )}

                {(tatReportModulePermissionStatus) && (
                  <div className="nav_list">
                    <RouterLink to="/dashboard/tat-report" className="nav_link">
                      <i className="nav-icon bi bi-columns-gap">&nbsp;</i>
                      <span className="nav_name">TAT Report V1</span>
                    </RouterLink>
                  </div>
                )}

                {(tatReportModulePermissionStatus) && (
                  <div className="nav_list">
                    <RouterLink to="/dashboard/all-lead-interactions" className="nav_link">
                      <i className="nav-icon bi bi-building">&nbsp;</i>
                      <span className="nav_name">TAT Report V2</span>
                    </RouterLink>
                  </div>
                )}

                {(exportSalesScoreModulePermissionStatus) && (
                  <div className="nav_list">
                    <RouterLink to="/dashboard/export-sales-score" className="nav_link">
                      <i className="nav-icon bi bi-columns-gap">&nbsp;</i>
                      <span className="nav_name">Sales and Credit Score</span>
                    </RouterLink>
                  </div>
                )}

                {(viewFIReportModulePermissionStatus) && (
                  <div className="nav_list">
                    <RouterLink to="/dashboard/fi-report" className="nav_link">
                      <i className="nav-icon bi bi-columns-gap">&nbsp;</i>
                      <span className="nav_name">FI Reported</span>
                    </RouterLink>
                  </div>
                )}

                {(viewAuditReportModulePermissionStatus) && (
                  <div className="nav_list">
                    <RouterLink to="/dashboard/allcloud-loans-report" className="nav_link">
                      <i className="nav-icon bi bi-building">&nbsp;</i>
                      <span className="nav_name">Loan Report</span>
                    </RouterLink>
                  </div>
                )}

                {(viewAuditReportModulePermissionStatus) && (
                  <div className="nav_list d-none">
                    <RouterLink to="/dashboard/audit-report" className="nav_link">
                      <i className="nav-icon bi bi-building">&nbsp;</i>
                      <span className="nav_name">Audit Report By Date</span>
                    </RouterLink>
                  </div>
                )}

                {(viewAuditReportModulePermissionStatus) && (
                  <div className="nav_list">
                    <RouterLink to="/dashboard/audit-report-data" className="nav_link">
                      <i className="nav-icon bi bi-building">&nbsp;</i>
                      <span className="nav_name">Audit Report</span>
                    </RouterLink>
                  </div>
                )}

                <div className="nav_list d-none-">
                  <RouterLink to="/dashboard/loan-applications" className="nav_link">
                    <i className="nav-icon bi bi-building">&nbsp;</i>
                    <span className="nav_name">Habile to AllCloud</span>
                  </RouterLink>
                </div>

                {(viewLoanBookModulePermissionStatus) && (

                  <div className="nav_list">
                    <RouterLink to="/dashboard/loan-book" className="nav_link">
                      <i className="nav-icon bi bi-building">&nbsp;</i>
                      <span className="nav_name">Loan Book</span>
                    </RouterLink>
                  </div>

                )}

                {(vieweNachReconModulePermissionStatus) && (
                  <div className="nav_list">
                    <RouterLink to="/dashboard/enach-recon" className="nav_link">
                      <i className="nav-icon bi bi-dice-6">&nbsp;</i>
                      <span className="nav_name">eNach Recon</span>
                    </RouterLink>
                  </div>
                )}

                {/* {(viewOTPOverrideModulePermissionStatus) && (
                  <div className="nav_list">
                    <RouterLink to="/dashboard/crif-otps-report" className="nav_link">
                      <i className="nav-icon bi bi-dice-6">&nbsp;</i>
                      <span className="nav_name">OTP Override</span>
                    </RouterLink>
                  </div>
                )} */}

                {(viewRiskMatrixModulePermissionStatus) && (

                <div className="nav_list">
                  <div id="riskMetricssubMenu">
                    <a
                      className="nav_link dropdown-toggle collapsed"
                      href="#riskMetricsMenu"
                      role="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#riskMetricsMenu"
                      aria-expanded="false"
                      aria-controls="riskMetricsMenu"
                    >
                      <i className="bi-person nav-icon">&nbsp;</i>
                      <span className="nav_link-title">Risk Metrics</span>
                    </a>

                    <div id="riskMetricsMenu" className="nav-collapse collapse ps-3" data-bs-parent="#riskMetricssubMenu">
                      <div className="nav_list">
                        <a href={`${appUrl}allcloud_apis/v2_calculation/risk_metrics_v4.php?referrer=${btoa('lms_page')}`} className="nav_link">
                          <i className="nav-icon bi bi-building">&nbsp;</i>
                          <span className="nav_name">Risk Metrics March</span>
                        </a>
                      </div>
                    </div>
                    
                    <div id="riskMetricsMenu" className="nav-collapse collapse ps-3" data-bs-parent="#riskMetricssubMenu">
                      <div className="nav_list">
                        <a href={`${appUrl}allcloud_apis/v2_calculation/risk_metrics_april.php?referrer=${btoa('lms_page')}`} className="nav_link">
                          <i className="nav-icon bi bi-building">&nbsp;</i>
                          <span className="nav_name">Risk Metrics April</span>
                        </a>
                      </div>
                    </div>

                    <div id="riskMetricsMenu" className="nav-collapse collapse ps-3" data-bs-parent="#riskMetricssubMenu">
                      <div className="nav_list">
                        <a href={`${appUrl}allcloud_apis/v2_calculation/risk_metrics_may.php?referrer=${btoa('lms_page')}`} className="nav_link">
                          <i className="nav-icon bi bi-building">&nbsp;</i>
                          <span className="nav_name">Risk Metrics May</span>
                        </a>
                      </div>
                    </div>

                    <div id="riskMetricsMenu" className="nav-collapse collapse ps-3 d-none" data-bs-parent="#riskMetricssubMenu">
                      <div className="nav_list">
                        <a href={`${appUrl}allcloud_apis/v2_calculation/risk_metrics_june.php?referrer=${btoa('lms_page')}`} className="nav_link">
                          <i className="nav-icon bi bi-building">&nbsp;</i>
                          <span className="nav_name">Risk Metrics June</span>
                        </a>
                      </div>
                    </div>

                    <div id="riskMetricsMenu" className="nav-collapse collapse ps-3" data-bs-parent="#riskMetricssubMenu">
                      <div className="nav_list">
                        <a href={`${appUrl}allcloud_apis/v2_calculation/risk_metrics_final.php?referrer=${btoa('lms_page')}`} className="nav_link">
                          <i className="nav-icon bi bi-building">&nbsp;</i>
                          <span className="nav_name">Risk Metrics</span>
                        </a>
                      </div>
                    </div>

                  </div>
                </div>

              )}

              {(userRoleType === "Superadmin") && (
                <div className="nav_list">
                  <div id="subMenu">
                    <a
                      className="nav_link dropdown-toggle collapsed"
                      href="#usersMenu2"
                      role="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#usersMenu2"
                      aria-expanded="false"
                      aria-controls="usersMenu2"
                    >
                      <i className="bi-person nav-icon">&nbsp;</i>
                      <span className="nav_link-title">CRIF Report</span>
                    </a>

                    <div id="usersMenu2" className="nav-collapse collapse ps-3" data-bs-parent="#subMenu">

                      <div className="nav_list">
                        <RouterLink to="/dashboard/import-report/bussiness" className="nav_link">
                          <i className="nav-icon bi bi-clipboard-check">&nbsp;</i>
                          <span className="nav_name">Import Business Report</span>
                        </RouterLink>
                      </div>
                      <div className="nav_list">
                        <RouterLink to="/dashboard/import-report/cibil" className="nav_link">
                          <i className="nav-icon bi bi-card-text">&nbsp;</i>
                          <span className="nav_name">Import Cibil Report</span>
                        </RouterLink>
                      </div>

                      <div className="nav_list">
                        <RouterLink to="/dashboard/import-report/ckers" className="nav_link">
                          <i className="nav-icon bi bi-building">&nbsp;</i>
                          <span className="nav_name">Export Ckers Finance Report</span>
                        </RouterLink>
                      </div>

                      <div className="nav_list">
                        <RouterLink to="/dashboard/ckers-finance-combine-report" className="nav_link">
                          <i className="nav-icon bi bi-distribute-vertical">&nbsp;</i>
                          <span className="nav_name" title="Ckers Finance Combine Report">Crif Portfolio</span>
                        </RouterLink>
                      </div>

                      <div className="nav_list">
                        <RouterLink to="/dashboard/import-report/consumer" className="nav_link">
                          <i className="bi bi-file-arrow-down">&nbsp;</i>
                          <span className="nav_name">Import Consumer Output</span>
                        </RouterLink>
                      </div>

                      <div className="nav_list">
                        <RouterLink to="/dashboard/import-report/details" className="nav_link">
                          <i className="bi bi-file-arrow-down">&nbsp;</i>
                          <span className="nav_name">Import Details Due Report</span>
                        </RouterLink>
                      </div>
                    </div>
                  </div>
                </div>
              )}



              </div>
            </div>
          )}


      {(viewReportsModulePermissionStatus || vieweNachReconModulePermissionStatus) && (
            <div className="nav_list">
              <a className="nav_link dropdown-toggle collapsed" href="#enachMenu" role="button" data-bs-toggle="collapse" data-bs-target="#enachMenu" aria-expanded="false" aria-controls="enachMenu">
                <i className="nav-icon bi bi-cash">&nbsp;</i>
                <span className="nav_link-title">E-NACH</span>
              </a>

              <div id="enachMenu" className="nav-collapse collapse ps-3" data-bs-parent="#mainNav">

              {(vieweNachReconModulePermissionStatus) && (
                  <div className="nav_list">
                    <RouterLink to="/dashboard/enach-recon" className="nav_link">
                      <i className="nav-icon bi bi-collection">&nbsp;</i>
                      <span className="nav_name">eNach Recon</span>
                    </RouterLink>
                  </div>
                )}

          {(vieweNachPresentationModulePermissionStatus) && (
                  <div className="nav_list">
                    <RouterLink to="/dashboard/enach-transaction-scheduling" className="nav_link">
                      <i className="nav-icon bi bi-cash-stack">&nbsp;</i>
                      <span className="nav_name">eNach Presentation</span>
                    </RouterLink>
                  </div>
                )}

                 
              
 
              </div>
            </div>
          )}

          {(viewIncidentReportPermissionStatus) && (
            <div className="nav_list">
              <a className="nav_link dropdown-toggle collapsed" href="#troubleTicket" role="button" data-bs-toggle="collapse" data-bs-target="#troubleTicket" aria-expanded="false" aria-controls="troubleTicket">
                <i className="nav-icon bi bi-file-text">&nbsp;</i>
                <span className="nav_link-title">Incidents</span>
              </a>

              <div id="troubleTicket" className="nav-collapse collapse ps-3" data-bs-parent="#mainNav">
                <div className="nav_list">
                  <RouterLink to="/dashboard/create-incident-reporting" className="nav_link">
                    <i className="nav-icon bi bi-file-earmark-plus">&nbsp;</i>
                    <span className="nav_name">Create Incident</span>
                  </RouterLink>
                </div>
                <div className="nav_list">
                  <RouterLink to="/dashboard/incident-report" className="nav_link">
                    <i className="nav-icon bi bi-file-earmark-post">&nbsp;</i>
                    <span className="nav_name">All Incidents</span>
                  </RouterLink>
                </div>

                <div className= {loggeInUserID === '49' || loggeInUserID === '50' ? "nav_list" : "nav_list d-none"}>
                  <RouterLink to="/dashboard/checklists" className="nav_link">
                    <i className="nav-icon bi bi-clipboard-check">&nbsp;</i>
                    <span className="nav_name">Checklist</span>
                  </RouterLink>
                </div>
              </div>
            </div>
          )}

          {(requestModulePermissionStatus) && (
            <div className="nav_list">
              <a className="nav_link dropdown-toggle collapsed" href="#moduleTypeName" role="button" data-bs-toggle="collapse" data-bs-target="#moduleTypeName" aria-expanded="false" aria-controls="moduleTypeName">
                <i className="nav-icon bi bi-file-text">&nbsp;</i>
                <span className="nav_link-title">{config.MODULE_TYPE_NAME}</span>
              </a>

              <div id="moduleTypeName" className="nav-collapse collapse ps-3" data-bs-parent="#mainNav">
                <div className="nav_list">
                  <RouterLink to="/dashboard/module-create-page" className="nav_link">
                    <i className="nav-icon bi bi-file-earmark-plus">&nbsp;</i>
                    <span className="nav_name">Create {config.MODULE_TYPE_NAME}</span>
                  </RouterLink>
                </div>
                <div className="nav_list">
                  <RouterLink to="/dashboard/module-listing-page" className="nav_link">
                    <i className="nav-icon bi bi-file-earmark-post">&nbsp;</i>
                    <span className="nav_name">All {config.MODULE_TYPE_NAME}</span>
                  </RouterLink>
                </div>
              </div>
            </div>
          )}


          {(viewBeatPlanModulePermissionStatus) && (
            <div className="nav_list">
              <a className="nav_link dropdown-toggle collapsed" href="#BeatPlanMenu" role="button" data-bs-toggle="collapse" data-bs-target="#BeatPlanMenu" aria-expanded="false" aria-controls="BeatPlanMenu">
                <i className="nav-icon bi bi-hdd-stack">&nbsp;</i>
                <span className="nav_link-title">Beat Plans</span>
              </a>

              <div id="BeatPlanMenu" className="nav-collapse collapse ps-3" data-bs-parent="#mainNav">

                <div className="nav_list">
                  <RouterLink to="/dashboard/create-beat-plan" className="nav_link">
                    <i className="nav-icon bi bi-database-add">&nbsp;</i>
                    <span className="nav_name">Create Beat Plan</span>
                  </RouterLink>
                </div>

                <div className="nav_list">
                  <RouterLink to="/dashboard/beat-plan" className="nav_link">
                    <i className="nav-icon bi bi-hdd">&nbsp;</i>
                    <span className="nav_name">Beat Plans</span>
                  </RouterLink>
                </div>

                <div className="nav_list">
                  <RouterLink to="/dashboard/visit-plan" className="nav_link">
                    <i className="nav-icon bi bi-hdd">&nbsp;</i>
                    <span className="nav_name">Visit Plans</span>
                  </RouterLink>
                </div>

                <div className="nav_list">
                  <RouterLink to="/dashboard/due-daily-beat" className="nav_link">
                    <i className="nav-icon bi bi-hdd">&nbsp;</i>
                    <span className="nav_name">Due Daily Beat</span>
                  </RouterLink>
                </div>

                <div className="nav_list">
                  <RouterLink to="/dashboard/beat-plan-gps" className="nav_link">
                    <i className="nav-icon bi bi-hdd">&nbsp;</i>
                    <span className="nav_name">User Visit Location</span>
                  </RouterLink>
                </div>

              </div>
            </div>
          )}

          {/* Collapse */}
          {(viewMastersModulePermissionStatus) && (
            <div className="nav_list">
              <a className="nav_link dropdown-toggle collapsed" href="#masterMenu" role="button" data-bs-toggle="collapse" data-bs-target="#masterMenu" aria-expanded="false" aria-controls="masterMenu">
                <i className="nav-icon bi bi-app">&nbsp;</i>
                <span className="nav_link-title">Master</span>
              </a>

              {/* {userRoleType === 'Superadmin' ? "nav_list" : "nav_list d-none"} */}

              <div id="masterMenu" className="nav-collapse collapse ps-3" data-bs-parent="#mainNav">
                { /* <div className="nav_list"> 
                    <RouterLink to="/dashboard/lead-qualification-checklist" className="nav_link"> 
                      <i className="nav-icon bi bi-clipboard-check">&nbsp;</i>
                      <span className="nav_name">Lead Qualification Checklist</span>
                    </RouterLink>
                </div> */ }
                
                {(viewChecklistsModulePermissionStatus) && (
                  <div className="nav_list">
                    <RouterLink to="/dashboard/checklists" className="nav_link">
                      <i className="nav-icon bi bi-clipboard-check">&nbsp;</i>
                      <span className="nav_name">Lead Qualification Checklist</span>
                    </RouterLink>
                  </div>
                )}

                {(viewDepartmentModulePermissionStatus) && (
                  <div className="nav_list">
                    <RouterLink to="/dashboard/department" className="nav_link">
                      <i className="nav-icon bi bi-building">&nbsp;</i>
                      <span className="nav_name">Department</span>
                    </RouterLink>
                  </div>
                )}

                {(viewUserRoleModulePermissionStatus) && (
                  <div className="nav_list">
                    <RouterLink to="/dashboard/user-role" className="nav_link">
                      <i className="nav-icon bi bi-person-vcard">&nbsp;</i>
                      <span className="nav_name">User Role</span>
                    </RouterLink>
                  </div>
                )}
                
                {(viewUserRoleModulePermissionStatus) && (
                  <div className="nav_list d-none">
                    <RouterLink to="/dashboard/role-permissions" className="nav_link">
                      <i className="nav-icon bi bi-person-lock">&nbsp;</i>
                      <span className="nav_name">Role Permission</span>
                    </RouterLink>
                  </div>
                )}

                {(viewLeadProfileModulePermissionStatus) && (
                  <div className="nav_list">
                    <RouterLink to="/dashboard/lead-profile" className="nav_link">
                      <i className="nav-icon bi bi-person-circle">&nbsp;</i>
                      <span className="nav_name">Lead Profile</span>
                    </RouterLink>
                  </div>
                )}

                {(viewSourceOfLeadModulePermissionStatus) && (
                  <div className="nav_list">
                    <RouterLink to="/dashboard/source-lead" className="nav_link">
                      <i className="nav-icon bi bi-file-break">&nbsp;</i>
                      <span className="nav_name">Source Lead</span>
                    </RouterLink>
                  </div>
                )}

                {(viewLeadStatusModulePermissionStatus) && (
                    <div className="nav_list">
                      <RouterLink to="/dashboard/lead-status" className="nav_link">
                        <i className="nav-icon bi bi-app-indicator">&nbsp;</i>
                        <span className="nav_name">Lead Status</span>
                      </RouterLink>
                    </div>
                )}

                {(viewCampaignsModulePermissionStatus) && (
                  <div className="nav_list">
                    <RouterLink to="/dashboard/campaigns" className="nav_link">
                      <i className="nav-icon bi bi-megaphone">&nbsp;</i>
                      <span className="nav_name">Campaign</span>
                    </RouterLink>
                  </div>  
                )}

                {(viewIncidentReportStatusModulePermissionStatus) && (
                  <div className="nav_list">
                    <RouterLink to="/dashboard/incident-report-status" className="nav_link">
                      <i className="nav-icon bi bi-card-text">&nbsp;</i>
                      <span className="nav_name">Incident Report Status</span>
                    </RouterLink>
                  </div>
                )}

                {(viewIncidentCategoryModulePermissionStatus) && (
                  <div className="nav_list">
                    <RouterLink to="/dashboard/incident-category" className="nav_link">
                      <i className="nav-icon bi bi-border-all">&nbsp;</i>
                      <span className="nav_name">Incident Category</span>
                    </RouterLink>
                  </div>
                )}

                {(viewUserGroupModulePermissionStatus) && (
                  <div className="nav_list">
                    <RouterLink to="/dashboard/incident-report-user-group" className="nav_link">
                      <i className="nav-icon bi bi-person-gear">&nbsp;</i>
                      <span className="nav_name">User Group</span>
                    </RouterLink>
                  </div>
                )}

                {(viewPrefillTemplateModulePermissionStatus) && (
                  <div className="nav_list">
                    <RouterLink to="/dashboard/prefill-template-name" className="nav_link">
                      <i className="nav-icon bi bi-file-earmark-ruled">&nbsp;</i>
                      <span className="nav_name">Prefill Template Name</span>
                    </RouterLink>
                  </div>
                )}

                {(viewDocumentTypeModulePermissionStatus) && (
                  <div className="nav_list">
                    <RouterLink to="/dashboard/document-type" className="nav_link">
                      <i className="nav-icon bi bi-file-earmark">&nbsp;</i>
                      <span className="nav_name">Document Type</span>
                    </RouterLink>
                  </div>
                )}

                {(viewVisitStatusModulePermissionStatus) && (
                  <div className="nav_list">
                    <RouterLink to="/dashboard/visit-status" className="nav_link">
                      <i className="nav-icon bi bi-file-earmark-ruled">&nbsp;</i>
                      <span className="nav_name">Visit Status</span>
                    </RouterLink>
                  </div>
                )}

                {(viewApprovalMasterModulePermissionStatus) && (
                  <div className="nav_list">
                    <RouterLink to="/dashboard/approval-master" className="nav_link">
                      <i className="nav-icon bi bi-file-check">&nbsp;</i>
                      <span className="nav_name">Approval Master</span>
                    </RouterLink>
                  </div>
                )}

                {(viewSubLeadStatusModulePermissionStatus) && (

                  <div className="nav_list">
                    <RouterLink to="/dashboard/sub-lead-status" className="nav_link">
                      <i className="nav-icon bi bi-building">&nbsp;</i>
                      <span className="nav_name">Sub Lead Status</span>
                    </RouterLink>
                  </div>

                )}
                
              </div>
            </div>
          )}


          {(viewTeamsPoorModulePermissionStatus) && (
            <div className="nav_list">
              <a className="nav_link dropdown-toggle collapsed" href="#TeamsPoorMenu" role="button" data-bs-toggle="collapse" data-bs-target="#TeamsPoorMenu" aria-expanded="false" aria-controls="TeamsPoorMenu">
                <i className="nav-icon bi bi-database-gear">&nbsp;</i>
                <span className="nav_link-title">Admin</span>
              </a>

              <div id="TeamsPoorMenu" className="nav-collapse collapse ps-3" data-bs-parent="#mainNav">

                <div className="nav_list">
                  <RouterLink to="/dashboard/planreport/visit" className="nav_link">
                    <i className="nav-icon bi bi-clipboard-check">&nbsp;</i>
                    <span className="nav_name">Visit Plans</span>
                  </RouterLink>
                </div>
                <div className="nav_list">
                  <RouterLink to="/dashboard/planreport/beat" className="nav_link">
                    <i className="nav-icon bi bi-building">&nbsp;</i>
                    <span className="nav_name">Beat Plans</span>
                  </RouterLink>
                </div>

              </div>
            </div>
          )}

          {/* Collapse End */}
        </div>
      </nav>
    </Box>
  );
}

function mapStateToProps(state) {
  return {
    getUserModuleData: state.getUserModuleData,
    userInfo: state.userInfo,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(userActions.default), dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavSection);

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item }) {
  const { title, path, icon, info } = item;

  return (
    <StyledNavItem
      component={RouterLink}
      to={path}
      sx={{
        '&.active': {
          color: 'text.primary',
          bgcolor: 'action.selected',
          fontWeight: 'fontWeightBold',
        },
      }}
    >
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

      <ListItemText disableTypography primary={title} />

      {info && info}
    </StyledNavItem>
  );
}
